<div [ngClass]="{ 'is-edit': (template$ | async).is_edit }" class="template-selector-grid-container">
  @if ((template$ | async)?.is_edit) {
    <app-floating-input
      #inputElement
      [name]="'template_name' + (template$ | async)?.id"
      class="template-name-input text-size_xl"
      [model]="(template$ | async)?.name"
      (modelChange)="onInputChange($event)"
      (inputBlurred)="setEditMode(false)"
      (iconClick)="setEditMode(false)"
      (enterPressed)="setEditMode(false)"
      [iconClass]="{
        position: 'p-input-icon-right',
        icon: 'icon-input-box-checked'
      }"
    />
    <!--      font-size: 22px;-->
    <!--      top: 17px;-->
    <!--      right: 6px;-->
    <!--      line-height: 1;-->
  } @else {
    <span>{{ (template$ | async)?.name }}</span>
  }

  @if ((template$ | async)?.has_error && !(template$ | async)?.is_edit) {
    <span
      pTooltip="An error occurred during saving this template. Please review it."
      tooltipPosition="top"
      class="icon-warning text-color_reject text-[22px]"
    ></span>
  } @else if ((template$ | async)?.is_pending && !(template$ | async)?.is_edit) {
    <span
      pTooltip="This template is not saved yet. Some fields may be missing, please review it."
      tooltipPosition="top"
      class="icon-warning text-color_accent_shade_1 text-[22px]"
    ></span>
  }

  @if (!(template$ | async)?.is_edit && (template$ | async)?.has_permissions) {
    <app-un-styled-options-list
      class="col-start-3"
      [listItems]="(template$ | async)?.permissions | optionPermissions"
      (selectedOption)="registerOption($event)"
      #unStyledOptionsList
    >
      <ng-template #projectedContent>
        <span
          (click)="unStyledOptionsList.optionsListGeneralComponent.toggleShow(); $event.stopPropagation()"
          class="icon-options-inverted text-[22px] cursor-pointer text-color_secondary flex items-center"
        ></span>
      </ng-template>
    </app-un-styled-options-list>
  }

  @if (!(template$ | async)?.is_edit && !(template$ | async)?.has_permissions) {
    <span
      [pTooltip]="templateTeamTooltip"
      tooltipPosition="bottom"
      class="col-start-3 icon-info-filled text-[22px] text-shade_1"
    ></span>

    <ng-template #templateTeamTooltip>
      <div class="flex flex-col items-center gap-2">
        <div class="text-size_m">Template Is Owned By</div>
        <div class="text-size_l font-semibold">{{ (template$ | async)?.team?.title }}</div>
      </div>
    </ng-template>
  }
</div>
