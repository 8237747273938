<ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
  <div class="flex my-2.5 mx-7 flex-col flex-1">
    <app-search-input
      (searchText)="onSearch($event)"
      placeholder="Search Line Items..."
      label=""
      (search)="onSearch($event)"
    ></app-search-input>
    <div class="flex items-center gap-2 text-size_xs text-shade_2 mr-16 font-bold px-2">
      <div class="min-w-10 uppercase">Index</div>
      <div class="w-full uppercase">Line Item</div>
      <div class="w-full uppercase">Dollar Amount</div>
    </div>
    <form #lineItemsPage="ngForm" role="form">
      @for (lineItem of filteredLineItems; track lineItem.id + '_' + index; let index = $index) {
        <div class="flex items-center gap-2">
          <div class="min-w-10 min-h-10 index-box">{{ lineItem.index }}</div>
          <app-dropdown
            [showError]="false"
            [optionLabel]="null"
            [optionValue]="'id'"
            [model]="lineItem.id"
            (modelChange)="onLineItemChange($event, index)"
            [required]="true"
            [showLabel]="false"
            placeholder=""
            label=""
            [name]="'line_item_' + lineItem.id"
            class="w-full max-w-40"
            [options]="allLineItems$ | async"
          >
            <ng-template #selectedContent #optionContent let-option>
              <div class="flex flex-col">
                <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
                <div class="capitalize text-color_primary font-normal">
                  {{ option?.division }} {{ option?.cost_type }}
                </div>
              </div>
            </ng-template>
          </app-dropdown>

          <app-floating-input
            required
            class="w-full overflow-hidden"
            [(model)]="lineItem.value"
            [name]="'value_' + lineItem.id"
            label=""
            placeholder=""
            [formatMoney]="true"
            [hideErrorMessage]="true"
          ></app-floating-input>

          <app-add-remove-buttons
            [disabledRemove]="model.lineItems.length === 1"
            [disabledAdd]="(allLineItems$ | async).length === model.lineItems.length"
            (removeItem)="removeItem(index)"
            (addItem)="addItem(index)"
          >
          </app-add-remove-buttons>
        </div>
      }
    </form>
  </div>
</ng-scrollbar>

<div class="mt-auto border-b border-b-solid border-shade_5"></div>

<div class="my-6 mx-7 flex justify-between gap-4">
  <div class="text-color_primary text-sm font-bold">Total Contract</div>
  <div class="text-color_primary text-sm font-bold">
    {{ totals | money: 'comaSign' }}
  </div>
  <app-simple-button (click)="goToNextPage()">Next</app-simple-button>
</div>
