import { IPermissions } from '../documents/documents.reducer';
import { PERMISSION_LITERALS } from '../../framework/constants/documents.constants';
import {
  IBudgetTagItem,
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItem,
  IBudgetTemplateItemGC,
  IBudgetTemplateItemManager,
  IBudgetTemplateSubitemGC,
  TemplateViews,
} from './templates.types';
import * as uuid from 'uuid';

export const defaultTemplatePermissions: Partial<IPermissions> = {
  [PERMISSION_LITERALS.CAN_EDIT]: 1,
  [PERMISSION_LITERALS.CAN_DELETE]: 1,
};
export const getDefaultProjectBudgetTemplate = (isManager: boolean): IBudgetTemplate => {
  return {
    id: uuid.v4(),
    name: '',
    is_deleted: false,
    is_edit: true,
    is_owner: true,
    permissions: { ...defaultTemplatePermissions },
    template_items: [getDefaultBudgetTemplateItem(isManager)],
  };
};

export const getDefaultBudgetTagTemplate = (): Partial<IBudgetTagTemplate> => {
  return {
    id: uuid.v4(),
    name: '',
    is_deleted: false,
    is_edit: true,
    is_owner: true,
    permissions: { ...defaultTemplatePermissions },
    tags: [getDefaultBudgetTagItem()],
  };
};

export const getDefaultBudgetTemplateItem = (
  isManager: boolean,
  order?: number,
): IBudgetTemplateItem => {
  const defaultItem = {
    id: uuid.v4(),
    order: order ?? 1,
    description: '',
    division: '', //todo: decide default values based on manager/GC
    cost_type: '', //todo: decide default values based on manager/GC
    account_code: '', //todo: decide default values based on manager/GC
    is_deleted: false,
    subitems: [], // todo: this could be optional based on GC or manager
  };

  if (isManager) {
    delete defaultItem.division;
    delete defaultItem.cost_type;
  } else {
    delete defaultItem.account_code;
  }

  return defaultItem;
};

export const getDefaultTemplateSubitem = (
  order?: number,
  parentItemId?: number,
): IBudgetTemplateSubitemGC => {
  return {
    id: uuid.v4(),
    order: order ?? 1,
    description: '',
    division: '', //todo: decide default values based on manager/GC
    cost_type: '', //todo: decide default values based on manager/GC
    is_deleted: false,
    parent_id: parentItemId,
  };
};

export const getDefaultBudgetTagItem = (order?: number): IBudgetTagItem => {
  return {
    id: uuid.v4(),
    name: '',
    order: order ?? 1,
    is_deleted: false,
    permissions: { ...defaultTemplatePermissions },
  };
};

/**
 * User-defined type guard to check if the template is a manager template.
 * Read more here: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
 */
export const isManagerTemplateItem = (
  template: IBudgetTemplateItem,
): template is IBudgetTemplateItemManager => {
  return 'account_code' in template;
};

/**
 * User-defined type guard to check if the template is a manager template.
 */
export const isGCTemplateItem = (
  template: IBudgetTemplateItem,
): template is IBudgetTemplateItemGC => {
  return 'division' in template;
};

export const DEFAULT_BUDGET_TAG_TEMPLATE_NAME = 'Budget Tag Template';
export const DEFAULT_BUDGET_TEMPLATE_NAME = 'Project Template';

export const getNextTemplateIndex = (
  templates: (IBudgetTemplate | IBudgetTagTemplate)[],
  type: TemplateViews,
): number => {
  const defaultName =
    type === 'budget' ? DEFAULT_BUDGET_TEMPLATE_NAME : DEFAULT_BUDGET_TAG_TEMPLATE_NAME;
  const names = templates
    .filter((template) => !template.is_deleted)
    .map((template) => template.name);
  const namesWithDefault = names.filter((name) => name.startsWith(defaultName));
  return namesWithDefault.length + 1;
};
