import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  IPrimeContractModel,
  primeRetainageRate,
} from 'src/app/store/prime-commitments/prime-commitments.types';
import { defaultRetainageLineItem } from '../../../../store/prime-commitments/prime-commitments.constants';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FormsModule, NgForm } from '@angular/forms';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-prime-invoice-retainage',
  standalone: true,
  imports: [
    DropdownComponent,
    FormsModule,
    FloatingInputComponent,
    SimpleButtonComponent,
    AddRemoveButtonsComponent,
    NgScrollbar,
  ],
  templateUrl: './prime-invoice-retainage.component.html',
  styleUrl: './prime-invoice-retainage.component.scss',
})
export class PrimeInvoiceRetainageComponent {
  @ViewChild('retainageSettings') retainageSettingsForm: NgForm;

  @Input() model: IPrimeContractModel;
  @Output() modelChange = new EventEmitter<IPrimeContractModel>();
  @Output() saveContract = new EventEmitter<void>();

  invoiceRetainageRates: primeRetainageRate[] = ['0', '5', '10', '15'];

  onLineItemChange(id: number, index: number) {
    const selectedLineItem = this.model.lineItems.find((i) => i.id === id);
    this.model.retainageSettings[index].name = selectedLineItem.name;
    this.model.retainageSettings[index].division = selectedLineItem.division;
    this.model.retainageSettings[index].cost_type = selectedLineItem.cost_type;
    this.model.retainageSettings[index].id = id;
  }

  addDefaultItem() {
    this.model.retainageSettings.push(
      defaultRetainageLineItem(this.model.defaultInvoiceRetainageRate),
    );
  }

  removeItem(index: number) {
    this.model.retainageSettings.splice(index, 1);
    this.reOrderLineItems();
  }

  addItem(index: number) {
    this.model.retainageSettings.splice(
      index + 1,
      0,
      defaultRetainageLineItem(this.model.defaultInvoiceRetainageRate),
    );
    this.reOrderLineItems();
  }

  reOrderLineItems() {
    this.model.retainageSettings = this.model.retainageSettings.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
  }

  isFormValid() {
    this.retainageSettingsForm.form.markAllAsTouched();
    return this.retainageSettingsForm.form.valid;
  }

  submitForm() {
    if (!this.isFormValid()) {
      return;
    }

    this.saveContract.emit();
  }
}
