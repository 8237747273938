import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { templatesActions } from './templates.actions';
import {
  auditTime,
  catchError,
  debounceTime,
  defaultIfEmpty,
  exhaustMap,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { TemplatesService } from '../../services/templates.service';
import { IBudgetTagTemplate, IBudgetTemplate } from './templates.types';
import { NotificationsService } from '../../services/notifications.service';
import * as uuid from 'uuid';
import { templateSelectors } from './templates.selectors';

import {
  DEFAULT_BUDGET_TAG_TEMPLATE_NAME,
  DEFAULT_BUDGET_TEMPLATE_NAME,
  getDefaultBudgetTagTemplate,
  getDefaultProjectBudgetTemplate,
  getNextTemplateIndex,
} from './templates.constants';
import { CurrentUserService } from '../../services/current-user.service';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class TemplatesEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private settingsService: TemplatesService,
    private notif: NotificationsService,
    private userService: CurrentUserService,
  ) {}

  loadBudgetTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(templatesActions.loadBudgetTemplate, templatesActions.reloadBudgetTemplates),
      tap(() => this.store.dispatch(templatesActions.setIsLoading({ isLoading: true }))),
      exhaustMap((): Observable<IBudgetTemplate[]> => this.settingsService.getBudgetTemplates()),
      map((templates: IBudgetTemplate[]) => {
        return templatesActions.setBudgetTemplates({ budgetTemplates: templates });
      }),
    ),
  );

  loadBudgetTagTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(templatesActions.loadBudgetTagTemplates, templatesActions.reloadBudgetTagTemplates),
      tap(() => this.store.dispatch(templatesActions.setIsLoading({ isLoading: true }))),
      switchMap(() => this.settingsService.getBudgetTagTemplates()),
      map((templates: IBudgetTagTemplate[]) => {
        return templatesActions.setBudgetTagTemplates({ budgetTagTemplates: templates });
      }),
    ),
  );

  loadBudgetTagTemplateCommitments = createEffect(() =>
    this.actions$.pipe(
      ofType(templatesActions.loadBudgetTagTemplatesFromCommitments),
      tap(() => this.store.dispatch(templatesActions.setIsLoading({ isLoading: true }))),
      switchMap((action) => this.settingsService.getBudgetTagTemplates(action.projectId)),
      map((templates: IBudgetTagTemplate[]) => {
        return templatesActions.setBudgetTagTemplates({ budgetTagTemplates: templates });
      }),
    ),
  );

  saveBudgetTemplates = createEffect(() => {
    let pendingIds = [];
    return this.actions$.pipe(
      ofType(templatesActions.saveBudgetTemplates),
      withLatestFrom(this.store.select(templateSelectors.templatesFeatureSelector)),
      switchMap(([action, state]) => {
        // if templateIds are not provided, save all templates
        const templateIds =
          action.templateIds ?? state.budgetTemplates.map((template) => template.id);
        pendingIds = action.pendingIds;
        const requests: Observable<IBudgetTemplate & { error?: boolean; old_id?: string }>[] = [];
        const allBudgetTemplates = state.budgetTemplates.filter(
          (template) => !template?.is_deleted && templateIds.includes(template.id),
        );

        allBudgetTemplates.forEach((template) => {
          // if new template it has an uuid, not default id from backend
          if (uuid.validate(template.id)) {
            requests.push(
              this.settingsService
                .createBudgetTemplate({
                  ...template,
                  id: undefined,
                })
                .pipe(
                  map((res) => ({ ...res, old_id: template.id as string })),
                  catchError((err) => of({ error: true, ...template })),
                ),
            );
          } else if (template.permissions?.can_edit) {
            requests.push(
              this.settingsService
                .updateBudgetTemplate(template)
                .pipe(catchError((err) => of({ error: true, ...template }))),
            );
          }
        });

        const deletedBudgetTemplates = state.budgetTemplates.filter(
          (template) =>
            template?.is_deleted &&
            !uuid.validate(template.id) &&
            template.permissions?.can_delete &&
            templateIds.includes(template.id),
        );
        deletedBudgetTemplates.forEach((template) => {
          requests.push(
            this.settingsService.deleteBudgetTemplate(template).pipe(
              catchError((err) => of({ error: true, ...template })),
              map(() => ({ ...template, is_deleted: true })),
            ),
          );
        });

        return forkJoin([...requests]).pipe(defaultIfEmpty([]));
      }),
      map((responses) => {
        if (!responses || responses.some((resp) => !resp || resp.error)) {
          return templatesActions.budgetTemplatesSaveError({
            errorMessage: 'Error saving templates',
            templateIds: responses.filter((resp) => resp.error).map((resp) => resp.id),
          });
        }

        return templatesActions.budgetTemplatesSavedSuccessFully({
          pendingIds,
          templates: responses,
        });
      }),
    );
  });

  saveBudgetTagTemplate = createEffect(() => {
    let pendingIds = [];
    return this.actions$.pipe(
      ofType(templatesActions.saveBudgetTagTemplate),
      concatLatestFrom(() => this.store.select(templateSelectors.templatesFeatureSelector)),
      switchMap(([action, state]) => {
        const requests: Observable<IBudgetTagTemplate & { error?: boolean; old_id?: string }>[] =
          [];
        pendingIds = action.pendingIds;
        const templateIds =
          action.templateIds ?? state.budgetTagTemplates.map((template) => template.id);
        const allTagTemplates = state.budgetTagTemplates.filter(
          (template) => !template?.is_deleted && templateIds.includes(template.id),
        );

        console.log('allTagTemplates', allTagTemplates);

        allTagTemplates.forEach((template) => {
          // if new account it has an uuid, not default id from backend
          if (uuid.validate(template.id)) {
            requests.push(
              this.settingsService.createBudgetTagTemplate({ ...template, id: undefined }).pipe(
                map((res) => ({ ...res, old_id: template.id as string })),
                catchError((err) => of({ error: true, ...template })),
              ),
            );
          } else if (template.permissions?.can_edit) {
            requests.push(
              this.settingsService
                .updateBudgetTagTemplate(template)
                .pipe(catchError((err) => of({ error: true, ...template }))),
            );
          }
        });

        const deletedTagTemplates = state.budgetTagTemplates.filter(
          (template) =>
            template?.is_deleted &&
            !uuid.validate(template.id) &&
            template.permissions?.can_delete &&
            templateIds.includes(template.id),
        );
        console.log('deletedTagTemplates', deletedTagTemplates);
        deletedTagTemplates.forEach((template) => {
          requests.push(
            this.settingsService
              .removeTagTemplate(template)
              .pipe(catchError((err) => of({ error: true, ...template }))),
          );
        });

        return forkJoin([...requests]).pipe(defaultIfEmpty([]));
      }),
      map((responses) => {
        if (!responses || responses.some((resp) => !resp || resp.error)) {
          return templatesActions.budgetTemplatesSaveError({
            errorMessage: 'Error saving templates',
            templateIds: responses.filter((resp) => resp.error).map((resp) => resp.id),
          });
        }

        return templatesActions.budgetTagsSavedSuccessFully({ templates: responses, pendingIds });
      }),
    );
  });

  addNewTemplateClicked = createEffect(() => {
    return this.actions$.pipe(
      ofType(templatesActions.addNewTemplateClicked),
      concatLatestFrom(() => this.store.select(templateSelectors.templatesFeatureSelector)),
      map(([action, state]) => {
        if (action.view === 'budget') {
          const newItem = {
            ...getDefaultProjectBudgetTemplate(this.userService.isManager),
            name: `${DEFAULT_BUDGET_TEMPLATE_NAME} ${getNextTemplateIndex(state.budgetTemplates, 'budget')}`,
          };
          this.store.dispatch(
            templatesActions.addBudgetTemplate({ budgetTemplate: newItem as IBudgetTemplate }),
          );
          return templatesActions.setSelectedBudgetTemplate({ templateId: newItem.id });
        }

        const newItem = {
          ...getDefaultBudgetTagTemplate(),
          name: `${DEFAULT_BUDGET_TAG_TEMPLATE_NAME} ${getNextTemplateIndex(state.budgetTagTemplates, 'budget-tag')}`,
        };
        this.store.dispatch(
          templatesActions.addBudgetTagTemplate({
            budgetTagTemplate: newItem as IBudgetTagTemplate,
          }),
        );
        return templatesActions.setSelectedBudgetTagTemplate({ templateId: newItem.id });
      }),
    );
  });

  selectNewBudgetTemplate = createEffect(() => {
    return this.actions$.pipe(
      ofType(templatesActions.addBudgetTemplate, templatesActions.addBudgetTemplateFromFile),
      map((action) => {
        return templatesActions.setSelectedBudgetTemplate({ templateId: action.budgetTemplate.id });
      }),
    );
  });

  selectNewBudgetTagTemplate = createEffect(() => {
    return this.actions$.pipe(
      ofType(templatesActions.addBudgetTagTemplate, templatesActions.addBudgetTagTemplateFromFile),
      map((action) => {
        return templatesActions.setSelectedBudgetTagTemplate({
          templateId: action.budgetTagTemplate.id,
        });
      }),
    );
  });

  autoSaveLoadingBudgetTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        templatesActions.addBudgetTemplate,
        templatesActions.updateBudgetTemplate,
        templatesActions.updateBudgetTemplateItem,
        templatesActions.updateBudgetTemplateSubitem,
        templatesActions.budgetTemplateItemDropped,
        templatesActions.budgetTemplateSubitemDropped,
        templatesActions.budgetTagTemplateItemDropped,
        templatesActions.removeBudgetTemplateItem,
        templatesActions.removeBudgetTemplateSubitem,
        templatesActions.deleteBudgetTemplate,
        templatesActions.addBudgetTemplateFromFile,
      ),

      map(() => {
        return templatesActions.budgetTemplatesSaveLoading();
      }),
    );
  });

  autoSaveBudgetTemplates$ = createEffect(() => {
    // these template ids should be validated thus can be saved
    const idsToSave = new Set<number | string>();

    // these template ids are partly empty, and should not be saved yet
    const pendingIds = new Set<number | string>();
    return this.actions$.pipe(
      ofType(
        templatesActions.addBudgetTemplate,
        templatesActions.updateBudgetTemplate,
        templatesActions.updateBudgetTemplateItem,
        templatesActions.updateBudgetTemplateSubitem,
        templatesActions.budgetTemplateItemDropped,
        templatesActions.budgetTemplateSubitemDropped,
        templatesActions.budgetTagTemplateItemDropped,
        templatesActions.removeBudgetTemplateItem,
        templatesActions.removeBudgetTemplateSubitem,
        templatesActions.deleteBudgetTemplate,
      ),
      concatLatestFrom(() => this.store.select(templateSelectors.templatesFeatureSelector)),
      tap(([action, state]) => {
        let templateId = state.selectedBudgetTemplateId;
        if (action.type === templatesActions.addBudgetTemplate.type) {
          templateId = action.budgetTemplate.id;
        }
        if (action.type === templatesActions.updateBudgetTemplate.type) {
          templateId = action.templateId;
        }
        if (action.type === templatesActions.deleteBudgetTemplate.type) {
          templateId = action.templateId;
        }

        const budgetTemplate = state.budgetTemplates.find((t) => t.id === templateId);
        const clearedTemplate = this.settingsService.clearBudgetTemplate(budgetTemplate);
        if (
          (!budgetTemplate?.name || !clearedTemplate.template_items?.length) &&
          action.type !== templatesActions.deleteBudgetTemplate.type
        ) {
          pendingIds.add(templateId);
          return;
        }

        idsToSave.add(templateId);
      }),
      // there are two kind of filters
      // to ensure a smooth saving - debounceTime only with a higher amount would make the user wait too long
      // auditTime/throttleTime only would send requests every X seconds without taking into account that the user may be editing
      // firstly debounceTime with a smaller time waits for the user to finish editing
      debounceTime(1500),
      // secondly, auditTime limits requests to be sent every X seconds
      // this is useful when the user edits a field, waits for 1500ms and then edits another field
      auditTime(4000),
      map(() => {
        const templateIds = Array.from(idsToSave);
        const pendingIdsArray = Array.from(pendingIds);
        idsToSave.clear();
        pendingIds.clear();
        return templatesActions.saveBudgetTemplates({ templateIds, pendingIds: pendingIdsArray });
      }),
    );
  });

  autoSaveFromFileBudgetTemplates$ = createEffect(() => {
    const templateIdsToSave = new Set<string>();
    return this.actions$.pipe(
      ofType(templatesActions.addBudgetTemplateFromFile),
      tap((action) => {
        templateIdsToSave.add(action.budgetTemplate.id as string);
      }),
      // wait a lot less when inserting templates from files.
      debounceTime(1000),
      map(() => {
        const templateIds = Array.from(templateIdsToSave);
        templateIdsToSave.clear();
        return templatesActions.saveBudgetTemplates({ templateIds });
      }),
    );
  });

  autoSaveLoadingTagTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        templatesActions.addBudgetTagTemplate,
        templatesActions.addBudgetTagTemplateItem,
        templatesActions.addEmptyBudgetTagTemplateItem,
        templatesActions.updateBudgetTagTemplate,
        templatesActions.updateBudgetTagTemplateItem,
        templatesActions.budgetTagTemplateItemDropped,
        templatesActions.deleteBudgetTagTemplate,
        templatesActions.removeBudgetTagTemplateItem,
        templatesActions.addBudgetTagTemplateFromFile,
      ),
      map(() => {
        return templatesActions.budgetTagTemplatesSaveLoading();
      }),
    );
  });

  autoSaveTags$ = createEffect(() => {
    const idsToSave = new Set<number | string>();
    const pendingIds = new Set<number | string>();
    return this.actions$.pipe(
      ofType(
        templatesActions.addBudgetTagTemplate,
        templatesActions.addBudgetTagTemplateItem,
        templatesActions.updateBudgetTagTemplate,
        templatesActions.updateBudgetTagTemplateItem,
        templatesActions.budgetTagTemplateItemDropped,
        templatesActions.deleteBudgetTagTemplate,
        templatesActions.removeBudgetTagTemplateItem,
      ),
      concatLatestFrom(() => this.store.select(templateSelectors.templatesFeatureSelector)),
      tap(([action, state]) => {
        console.log('autoSaveTags$', action, state);
        let templateId = state.selectedBudgetTagTemplateId;
        if (action.type === templatesActions.addBudgetTagTemplate.type) {
          templateId = action.budgetTagTemplate.id;
        }
        if (action.type === templatesActions.updateBudgetTagTemplate.type) {
          templateId = action.templateId;
        }
        if (action.type === templatesActions.deleteBudgetTagTemplate.type) {
          templateId = action.templateId;
        }

        console.log('templateId', templateId);

        const tagTemplate = state.budgetTagTemplates.find((t) => t.id === templateId);
        console.log('tagTemplate', tagTemplate);
        const clearedTemplate = this.settingsService.clearTagsTemplate(tagTemplate);
        if (
          (!tagTemplate?.name || !clearedTemplate.tags?.length) &&
          action.type !== templatesActions.deleteBudgetTagTemplate.type
        ) {
          pendingIds.add(templateId);
          return;
        }

        idsToSave.add(templateId);
      }),
      debounceTime(1500),
      auditTime(4000),
      map(() => {
        const templateIds = Array.from(idsToSave);
        const pendingIdsArray = Array.from(pendingIds);
        idsToSave.clear();
        pendingIds.clear();
        return templatesActions.saveBudgetTagTemplate({ templateIds, pendingIds: pendingIdsArray });
      }),
    );
  });

  autoSaveFromFileBudgetTagTemplates$ = createEffect(() => {
    const templateIdsToSave = new Set<string>();
    return this.actions$.pipe(
      ofType(templatesActions.addBudgetTagTemplateFromFile),
      tap((action) => {
        templateIdsToSave.add(action.budgetTagTemplate.id as string);
      }),
      // wait a lot less when inserting templates from files.
      debounceTime(1000),
      map(() => {
        const templateIds = Array.from(templateIdsToSave);
        templateIdsToSave.clear();
        return templatesActions.saveBudgetTagTemplate({ templateIds });
      }),
    );
  });
}
