import { Component, ViewChild } from '@angular/core';
import { AsyncPipe, NgClass, NgIf, TitleCasePipe } from '@angular/common';
import { DynamicProjectedTableComponent } from '../../../framework/dynamic-projected-table/dynamic-projected-table.component';
import { FadedTextComponent } from '../../../framework/faded-text/faded-text.component';
import { MoneyPipe } from '../../../pipes/framework/money-short.pipe';
import { ProgressBarProjectableComponent } from '../../../framework/progress-bar-projectable/progress-bar-projectable.component';
import { SimpleSmallButtonComponent } from '../../../framework/buttons/simple-small-button/simple-small-button.component';
import { SortArrowComponent } from '../../../framework/sort-arrow/sort-arrow.component';
import { StatusDropdownComponent } from '../../../status-dropdown/status-dropdown.component';
import { TooltipModule } from 'primeng/tooltip';
import { StorageService } from '../../../services/storage.service';
import { BaseListingComponent } from '../baseComponents/base-listing.component';
import { GeneralHeaderComponent } from '../../../framework/general-header/general-header.component';
import { DropdownComponent } from '../../../framework/inputs/dropdown/dropdown.component';
import { getProjectStatusesExcept } from '../../../store/projects/projects.selectors';
import { Observable, of } from 'rxjs';
import { ProjectStatus } from '../projects/projects.interface';
import { SearchInputComponent } from '../../../framework/search-input/search-input.component';

/**
 * Starting point for contractor project listing, will do more as Figma design advances and is in a final stage
 */
@Component({
  selector: 'app-contractors-project-listing',
  standalone: true,
  imports: [
    AsyncPipe,
    DynamicProjectedTableComponent,
    FadedTextComponent,
    MoneyPipe,
    NgIf,
    ProgressBarProjectableComponent,
    SimpleSmallButtonComponent,
    SortArrowComponent,
    StatusDropdownComponent,
    TooltipModule,
    NgClass,
    GeneralHeaderComponent,
    DropdownComponent,
    TitleCasePipe,
    SearchInputComponent,
  ],
  templateUrl: './contractors-project-listing.component.html',
  styleUrl: './contractors-project-listing.component.scss',
})
export class ContractorsProjectListingComponent extends BaseListingComponent {
  // todo: add types for contractors project listing
  // todo: this is a only a starting point, to be completed as figma is completed
  // projects$: Observable<any> = of([]);
  // projects: IRollupProjectTable[] = [];
  // projectTotals$ = this.store.select(getRollupProjectsListTotal);

  // isLoading$ = this.store.select(isLoading);
  // isFetching$ = this.store.select(isFetching);

  projectStatusIds: number[] = [...StorageService.getRollupFilters().project_status];

  @ViewChild('dynamicProjectedTable') dynamicProjectedTable: DynamicProjectedTableComponent;

  // todo move
  allProjectStatus$: Observable<ProjectStatus[]> = this.store.select(
    getProjectStatusesExcept(['deleted']),
  );

  // todo: make sure this is correct
  onStatusChange(projectStatusIds: number[]) {
    this.projectStatusIds = projectStatusIds;
    StorageService.setRollupFilters({
      ...StorageService.getRollupFilters(),
      project_status: this.projectStatusIds,
    });
    this.reloadProjects();
  }

  // todo: reload
  properties$ = of([]);
  filterByPropertyIds = [];
  reloadProjects() {
    console.warn('TODO: reload projects');
  }

  onPropertySelect($event: any) {
    console.warn('TODO: ');
  }

  onSearch($event: string) {
    console.warn('TODO: ');
  }
}
