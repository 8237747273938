<section class="assign-teammate-wrapper flex flex-col">
  <div>
    <app-floating-input
      (modelChange)="searchTeamMates($event)"
      [verticalSpacing]="false"
      [iconClass]="{ position: 'p-input-icon-right', icon: 'icon-search text-color_secondary' }"
      placeholder="Search"
    ></app-floating-input>
  </div>
  <div class="flex-1 mt-2 min-h-0">
    @if (isLoading) {
      <!--    <div class="no-results">-->
      <!--      <span>Loading...</span>-->
      <!--    </div>-->
      <div class="flex w-full h-full items-center justify-center">
        <div class="notify-msg animate-pulsating-border">
          <div class="icon-logo-box-hex"></div>
        </div>
      </div>
    } @else if (errorFetchingMembers) {
      <div class="no-results text-color_reject">
        <span>An error occurred while loading team members.</span>
      </div>
    } @else if (displayedTeamMates.length === 0) {
      <div class="no-results">
        <span>No results found.</span>
      </div>
    } @else {
      <ng-scrollbar appearance="native" class="std-scrollbar" orientation="vertical">
        @for (teamMate of displayedTeamMates; track teamMate.id) {
          <div (click)="onTeamMateSelect(teamMate)" class="teammate-row">
            <div class="profile-pic-container">
              @if (teamMate.profile_picture_url) {
                <img class="profile-pic" [src]="teamMate.profile_picture_url" alt="profile picture" />
              } @else {
                <span class="initials">{{ teamMate.initials }}</span>
              }
            </div>
            <div class="font-semibold text-size_l">
              <span>{{ teamMate.first_name + ' ' + teamMate.last_name }}</span>
            </div>
            <div class="text-size_m">
              {{ teamMate.home_base?.name ?? '' }}
            </div>
            <div class="text-size_m">
              {{ teamMate.role }}
            </div>
          </div>
        }
      </ng-scrollbar>
    }
  </div>
</section>
