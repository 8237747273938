import { Component, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { UploadWindowComponent } from '../../../upload/upload-window/upload-window.component';
import { TooltipModule } from 'primeng/tooltip';
import { ShortenFileNamePipe } from '../../../../pipes/framework/shorten-file-name.pipe';
import { NgClass } from '@angular/common';
import { exportOneTemplate } from '../../../../pages/webapp/settings/templates/templates-export-import-utilities';
import { Observable } from 'rxjs';
import {
  IPrimeLineItem,
  IPrimeLineItemResponse,
} from '../../../../store/prime-commitments/prime-commitments.types';
import { primeCommitmentsSelectors } from '../../../../store/prime-commitments/prime-commtiments.selectors';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { NotificationsService } from 'src/app/services/notifications.service';
import { read, utils, WorkBook } from 'xlsx';

@Component({
  selector: 'app-prime-contract-upload-template',
  standalone: true,
  imports: [UploadWindowComponent, TooltipModule, ShortenFileNamePipe, NgClass],
  templateUrl: './prime-contract-upload-template.component.html',
})
export class PrimeContractUploadTemplateComponent implements OnInit {
  @Output() parsedData = new EventEmitter<IPrimeLineItem[]>();

  file: File | null = null;

  private readonly store = inject(Store);
  private readonly notif = inject(NotificationsService);

  allLineItems$: Observable<IPrimeLineItemResponse[]> = this.store.select(
    primeCommitmentsSelectors.selectAllLineItems,
  );

  allLineItems: IPrimeLineItemResponse[] = [];

  ngOnInit() {
    this.allLineItems$
      .pipe(
        filter((it) => !!it.length),
        take(1),
      )
      .subscribe((allLineItems) => {
        this.allLineItems = allLineItems;
      });
  }

  async registerFileUpload(files: FileList) {
    this.file = files[0];
    const shouldUploadData = await this.notif.showPopup(
      'Template successfully uploaded. Do you want to replace the existing template?',
    );
    if (!shouldUploadData) {
      this.removeFile();
      return;
    }

    const buffer = await this.file.arrayBuffer();
    const workbook = read(buffer);
    // some Apple stuff, Numbers file exported to XLSX contains a useless sheet with the name "Export Summary"
    const sheetNames = workbook.SheetNames.filter((name) => !name.includes('Export Summary'));

    if (!workbook || !sheetNames.length) {
      this.notif.showError("Error: the selected file doesn't contain any templates.");
      return;
    }

    const excelSheetName = sheetNames[0];
    const templateData = this.getDataFromWorkbook(workbook, excelSheetName);
    const verifiedData = templateData.filter((item) => item.id);
    this.parsedData.emit(verifiedData);
  }

  getDataFromWorkbook(workbook: WorkBook, sheetName: string): IPrimeLineItem[] {
    const workbookData = utils.sheet_to_json<IPrimeLineItem>(workbook.Sheets[sheetName]);
    return workbookData.map((item: IPrimeLineItem, index: number) => {
      return {
        id: item?.id ?? '',
        name: item?.name ?? '',
        division: item?.division ?? '',
        cost_type: item?.cost_type ?? '',
        value: item?.value ?? 0,
        index: index,
      } as IPrimeLineItem;
    });
  }

  removeFile() {
    this.file = null;
  }

  downloadXLSX() {
    try {
      const sheetData = this.convertToSheetData();
      exportOneTemplate(sheetData, 'Prime Contract Values', `PrimeContractValuesTemplate.xlsx`);
    } catch (e) {
      console.error('Error exporting template', e);
      this.notif.showError('Error exporting the template.');
    }
  }

  convertToSheetData() {
    if (!this.allLineItems?.length) {
      return [];
    }

    return this.allLineItems.map((lineItem) => {
      return {
        id: lineItem.id,
        name: lineItem.name,
        division: lineItem.division,
        cost_type: lineItem.cost_type,
        value: lineItem.current_budget,
      };
    });
  }
}
