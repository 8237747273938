import {
  ContractValue,
  IPrimeContractModel,
  IPrimeContractModelResponse,
  IPrimeLineItem,
  IPrimeLineItemMarkup,
  IPrimeLineItemResponse,
  IPrimeRetainageItem,
  PrimeCommitmentsState,
  primeRetainageRate,
} from './prime-commitments.types';
import * as uuid from 'uuid';

export const initialState: PrimeCommitmentsState = {
  allLineItems: [],
};

export const primeContractStatuses = ['approved', 'pending', 'review', 'draft'];

export const defaultPrimeLineItem = (index: number): IPrimeLineItem => {
  return {
    id: uuid.v4(),
    name: '',
    value: 0,
    division: '',
    cost_type: '',
    index: index,
  };
};

export const defaultMarkupItem = (value = null): IPrimeLineItemMarkup => {
  return {
    id: uuid.v4(),
    name: '',
    value,
    division: '',
    cost_type: '',
  };
};

export const defaultRetainageLineItem = (value = null): IPrimeRetainageItem =>
  defaultMarkupItem(value);

export const primeContractResponseToModel = (
  contract: IPrimeContractModelResponse,
  lineItems: IPrimeLineItemResponse[],
): IPrimeContractModel => {
  // Map for quick lookup of line item details
  const lineItemMap = new Map(lineItems.map((item) => [item.id, item]));

  const mapLineItem = (item: ContractValue): IPrimeLineItem => {
    const lineItem = lineItemMap.get(item.item_id);
    return {
      id: item.item_id,
      name: lineItem?.name ?? '',
      division: lineItem?.division ?? '',
      cost_type: lineItem?.cost_type ?? '',
      value: item?.value ?? null,
      index: item.index,
    };
  };

  return {
    id: contract.id,
    files: contract?.files ?? [],
    name: contract.title,
    owner_name: contract.owner_name,
    date: contract.date,
    status: contract.status,
    note: contract.description,
    defaultInvoiceRetainageRate: String(
      contract.default_invoice_retainage_rate,
    ) as primeRetainageRate,
    lineItems: contract.contract_values.map(mapLineItem),
    lineItemMarkup: contract.contract_values
      .filter((item) => item.change_order_markup_rate !== null)
      .map((item) => ({
        ...mapLineItem(item),
        value: item.change_order_markup_rate,
      })),
    retainageSettings: contract.contract_values
      .filter((item) => item.invoice_retainage_rate !== null)
      .map((item) => ({
        ...mapLineItem(item),
        value: item.invoice_retainage_rate,
      })),
  };
};
