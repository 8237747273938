<div class="template-view-wrapper">
  <div class="template-header main-grid-container">
    <div class="template-selector-grid-container">
      <span>Template Name</span>
      <span
        (click)="addTemplateClicked()"
        class="col-start-3 icon-add-block-square text-color_secondary text-[22px]"
      ></span>
    </div>
    <div
      [ngClass]="{
        'budget-manager': userService.isManager,
        'budget-gc': userService.isGeneralContractor
      }"
      class="template-lines-grid-container mb-0"
    >
      @if (userService.isManager) {
        <div class="col-start-3">Account Code</div>
      } @else if (userService.isGeneralContractor) {
        <div class="col-start-3">Division</div>
        <div class="">Cost type</div>
      }
      <div>Description</div>
      <div
        [ngClass]="{
          'col-start-5': userService.isManager,
          'col-start-6': userService.isGeneralContractor
        }"
      >
        <app-upload-window
          (chosenFiles)="spreadSheetLoaded($event)"
          iconClass="icon-folded-file-upload text-[22px] cursor-pointer hover:brightness-[117%]"
          acceptFileTypes=".xlsx"
          [resetFilesAfterEmit]="true"
        ></app-upload-window>
      </div>
      <div #exportTemplateBtn="cdkOverlayOrigin" cdkOverlayOrigin (click)="isDropdownShown = true" class="max-h-[30px]">
        <span class="icon-download-template text-[22px] text-shade_1 cursor-pointer hover:brightness-[117%]"></span>
      </div>
      <app-options-list-general
        [trigger]="exportTemplateBtn"
        [(show)]="isDropdownShown"
        (close)="isDropdownShown = false"
        [overlayPositions]="defaultDropdownOverlayPositions"
      >
        <div class="bg-white shadow-md p-2">
          <div
            (click)="exportOneTemplate()"
            [ngClass]="{ 'disabled text-shade_3': !(selectedTemplate$ | async)?.template_items?.length }"
            class="cursor-pointer text-size_l font-semibold text-shade_1 hover:text-color_accent"
          >
            Export only this template
          </div>
          <div
            (click)="exportAllTemplates()"
            class="cursor-pointer text-size_l font-semibold text-shade_1 hover:text-color_accent"
          >
            Export all templates
          </div>
        </div>
      </app-options-list-general>
    </div>
  </div>

  <div class="main-grid-container flex-1 my-2 min-h-0">
    @if ((templates$ | async).length === 0) {
      <app-general-empty-splash class="col-start-1 col-end-3" title="You currently don't have any project templates.">
        <app-simple-button buttonStyle="color_secondary_filled" size="large" (click)="addTemplateClicked()"
          >Add a Template</app-simple-button
        >
      </app-general-empty-splash>
    } @else {
      <app-template-list type="budget"></app-template-list>
      @if (!templateItems?.length) {
        <div>
          <app-general-empty-splash
            class="col-start-1 col-end-8"
            title="There are no template lines to show."
          ></app-general-empty-splash>
        </div>
      } @else {
        <div
          cdkDropList
          cdkScrollable
          cdkDropListOrientation="vertical"
          [cdkDropListData]="templateItems"
          (cdkDropListDropped)="itemDropped($event)"
          class="mr-2 cdk-scrollable"
        >
          @for (templateItem of templateItems; track templateItem.id; let index = $index) {
            <div cdkDrag [cdkDragData]="templateItem" class="item-container">
              <div
                [ngClass]="{
                  'budget-manager': userService.isManager,
                  'budget-gc': userService.isGeneralContractor
                }"
                class="template-lines-grid-container"
              >
                <app-template-item-project-budget
                  [template]="selectedTemplate$ | async"
                  [templateItem]="templateItem"
                ></app-template-item-project-budget>
              </div>

              @if (isGCTemplate(templateItem)) {
                <div
                  class="subitems-container"
                  cdkDropList
                  [cdkDropListData]="templateItem.subitems"
                  (cdkDropListDropped)="subitemDropped($event, templateItem)"
                >
                  @for (subItem of templateItem?.subitems ?? []; track subItem.id; let subIndex = $index) {
                    <div
                      cdkDrag
                      [cdkDragData]="subItem"
                      [ngClass]="{
                        'budget-manager': userService.isManager,
                        'budget-gc': userService.isGeneralContractor
                      }"
                      class="template-lines-grid-container"
                    >
                      <app-template-subitem
                        [template]="selectedTemplate$ | async"
                        [templateItem]="templateItem"
                        [templateSubitem]="subItem"
                      ></app-template-subitem>
                    </div>
                  }
                </div>
              }
            </div>
          }

          @if (userService.isGeneralContractor) {
            <div>
              <div
                class="index-box text-[28px] leading-[1.2] bg-color_secondary text-white hover:brightness-110 cursor-pointer"
                (click)="addTemplateItem()"
              >
                +
              </div>
            </div>
          }
        </div>
      }
    }
  </div>
</div>
