import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItemGC,
  ITemplatesState,
  TEMPLATES_AUTO_SAVE_STATES,
} from './templates.types';
import { createReducer, on } from '@ngrx/store';
import { templatesActions } from './templates.actions';
import { DeepCopyService } from '../../services/deep-copy.service';
import * as uuid from 'uuid';

import {
  defaultTemplatePermissions,
  getDefaultBudgetTagItem,
  getDefaultBudgetTemplateItem,
  getDefaultTemplateSubitem,
} from './templates.constants';

export const templatesFeatureKey = 'templates';

export const templatesInitialState: ITemplatesState = {
  budgetTemplates: [],
  selectedBudgetTemplateId: null,
  selectedBudgetTagTemplateId: null,
  budgetTagTemplates: [],
  isLoading: false, // general loading indicator
  autoSaveState: {
    budgetTemplates: TEMPLATES_AUTO_SAVE_STATES.SAVED_SUCCESSFULLY,
    tagTemplates: TEMPLATES_AUTO_SAVE_STATES.SAVED_SUCCESSFULLY,
  },
};

export const templatesReducer = createReducer(
  templatesInitialState,
  on(templatesActions.setIsLoading, (state, action): ITemplatesState => {
    return { ...state, isLoading: action.isLoading };
  }),
  on(templatesActions.setBudgetTemplates, (state, action): ITemplatesState => {
    return { ...state, budgetTemplates: action.budgetTemplates, isLoading: false };
  }),
  on(templatesActions.setBudgetTagTemplates, (state, action): ITemplatesState => {
    return { ...state, budgetTagTemplates: action.budgetTagTemplates, isLoading: false };
  }),

  on(templatesActions.setSelectedBudgetTemplate, (state, action): ITemplatesState => {
    return { ...state, selectedBudgetTemplateId: action.templateId };
  }),

  on(templatesActions.setSelectedBudgetTagTemplate, (state, action): ITemplatesState => {
    return { ...state, selectedBudgetTagTemplateId: action.templateId };
  }),

  on(
    templatesActions.addBudgetTemplate,
    templatesActions.addBudgetTemplateFromFile,
    (state, action): ITemplatesState => {
      return {
        ...state,
        budgetTemplates: [...state.budgetTemplates, action.budgetTemplate as IBudgetTemplate],
      };
    },
  ),
  on(
    templatesActions.addBudgetTagTemplate,
    templatesActions.addBudgetTagTemplateFromFile,
    (state, action): ITemplatesState => {
      return {
        ...state,
        budgetTagTemplates: [
          ...state.budgetTagTemplates,
          action.budgetTagTemplate as IBudgetTagTemplate,
        ],
      };
    },
  ),

  on(templatesActions.addBudgetTemplateItem, (state, action): ITemplatesState => {
    if (!state.selectedBudgetTemplateId) {
      return state;
    }
    const budgetTemplates = DeepCopyService.deepCopy(state.budgetTemplates);
    const index = budgetTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    budgetTemplates[index].template_items.push(action.newItem);
    return { ...state, budgetTemplates: [...budgetTemplates] };
  }),

  on(templatesActions.addEmptyBudgetTemplateItem, (state, action): ITemplatesState => {
    if (!state.selectedBudgetTemplateId) {
      return state;
    }
    const selectedTemplate = state.budgetTemplates.find(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    if (!selectedTemplate) {
      return state;
    }

    const newTemplateItem = getDefaultBudgetTemplateItem(
      action.isManager,
      selectedTemplate.template_items.length + 1,
    );
    const budgetTemplates = state.budgetTemplates.map((template) => {
      if (template.id === state.selectedBudgetTemplateId) {
        return { ...template, template_items: [...template.template_items, newTemplateItem] };
      }
      return template;
    });
    return { ...state, budgetTemplates: [...budgetTemplates] };
  }),

  on(templatesActions.addEmptySubitemToBudgetTemplateItem, (state, action): ITemplatesState => {
    if (!state.selectedBudgetTemplateId) {
      return state;
    }
    const allBudgetTemplates = state.budgetTemplates as IBudgetTemplate<IBudgetTemplateItemGC>[];

    let selectedTemplate = allBudgetTemplates.find(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    if (!selectedTemplate) {
      return state;
    }
    selectedTemplate = DeepCopyService.deepCopy(selectedTemplate);
    const templateItem = selectedTemplate.template_items.find((item) => item.id === action.itemId);
    const newSubitem = getDefaultTemplateSubitem(templateItem.subitems.length + 1, action.itemId);

    selectedTemplate.template_items = selectedTemplate.template_items.map((item) => {
      if (item.id === action.itemId) {
        return { ...item, subitems: [...item.subitems, newSubitem] };
      }
      return item;
    });

    const budgetTemplates = allBudgetTemplates.map((template) =>
      template.id === state.selectedBudgetTemplateId ? selectedTemplate : template,
    );

    return { ...state, budgetTemplates };
  }),

  on(templatesActions.addEmptyBudgetTagTemplateItem, (state): ITemplatesState => {
    if (!state.selectedBudgetTagTemplateId) {
      return state;
    }
    const selectedTemplate = state.budgetTagTemplates.find(
      (t) => t.id === state.selectedBudgetTagTemplateId,
    );
    if (!selectedTemplate) {
      return state;
    }

    const newTemplateItem = getDefaultBudgetTagItem(selectedTemplate.tags.length + 1);
    return {
      ...state,
      budgetTagTemplates: state.budgetTagTemplates.map((template) => {
        if (template.id !== state.selectedBudgetTagTemplateId) {
          return template;
        }

        return {
          ...template,
          tags: [...template.tags, newTemplateItem],
        };
      }),
    };
  }),

  on(templatesActions.unlinkBudgetTemplateItem, (state, action): ITemplatesState => {
    const budgetTemplates = DeepCopyService.deepCopy(state.budgetTemplates);
    const selectedTemplateIndex = budgetTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    const templateItems = budgetTemplates[selectedTemplateIndex].template_items;
    const itemIndex = templateItems.findIndex((item) => item.id === action.budgetTemplateItem.id);
    templateItems[itemIndex].g_l_account_label = null;

    return { ...state, budgetTemplates: [...budgetTemplates] };
  }),

  on(templatesActions.addBudgetTagTemplateItem, (state, action): ITemplatesState => {
    const budgetTagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedIndex = budgetTagTemplates.findIndex(
      (template) => template.id === state.selectedBudgetTagTemplateId,
    );

    budgetTagTemplates[selectedIndex].tags.push({
      id: uuid.v4(),
      name: action.newItemName,
      permissions: { ...defaultTemplatePermissions },
    });

    return { ...state, budgetTagTemplates };
  }),
  on(templatesActions.updateBudgetTemplateItem, (state, action): ITemplatesState => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplate = budgetTemplates.find(
      (template) => template.id === state.selectedBudgetTemplateId,
    );

    selectedTemplate.template_items = selectedTemplate.template_items.map((item) => {
      if (item.id === action.templateItemId) {
        return { ...item, ...action.templateItem };
      }
      return item;
    });

    return { ...state, budgetTemplates };
  }),

  on(templatesActions.updateBudgetTemplateSubitem, (state, action): ITemplatesState => {
    if (!state.selectedBudgetTemplateId) {
      return state;
    }
    const budgetTemplates = (state.budgetTemplates as IBudgetTemplate<IBudgetTemplateItemGC>[]).map(
      (template) => {
        if (template.id !== state.selectedBudgetTemplateId) {
          return template;
        }
        return {
          ...template,
          template_items: template.template_items.map((item) => {
            if (item.id !== action.templateItemId) {
              return item;
            }
            return {
              ...item,
              subitems: item.subitems.map((subitem) => {
                if (subitem.id !== action.templateSubitemId) {
                  return subitem;
                }
                return { ...subitem, ...action.templateSubitem };
              }),
            };
          }),
        };
      },
    );

    return { ...state, budgetTemplates };
  }),

  on(templatesActions.updateBudgetTagTemplateItem, (state, action): ITemplatesState => {
    if (!state.selectedBudgetTagTemplateId) {
      return state;
    }
    const budgetTagTemplates = state.budgetTagTemplates.map((template) => {
      if (template.id !== state.selectedBudgetTagTemplateId) {
        return template;
      }

      return {
        ...template,
        tags: template.tags.map((item) => {
          if (item.id !== action.templateItemId) {
            return item;
          }

          return {
            ...item,
            ...action.templateItem,
          };
        }),
      };
    });

    return { ...state, budgetTagTemplates };
  }),
  on(templatesActions.updateBudgetTemplate, (state, action): ITemplatesState => {
    const budgetTemplates = state.budgetTemplates.map((template) => {
      if (template.id === action.templateId) {
        return { ...template, ...action.template };
      }
      return template;
    });
    return { ...state, budgetTemplates };
  }),
  on(templatesActions.updateBudgetTagTemplate, (state, action): ITemplatesState => {
    const budgetTagTemplates = state.budgetTagTemplates.map((template) => {
      if (template.id === action.templateId) {
        return { ...template, ...action.template };
      }
      return template;
    });
    return { ...state, budgetTagTemplates };
  }),
  on(templatesActions.budgetTemplateItemDropped, (state, action): ITemplatesState => {
    const budgetTemplates = state.budgetTemplates.map((template) => {
      if (template.id === state.selectedBudgetTemplateId) {
        return {
          ...template,
          template_items: action.templateItems.map((item, index) => ({
            ...item,
            order: index + 1,
          })),
        };
      }
      return template;
    });

    return { ...state, budgetTemplates };
  }),

  on(templatesActions.budgetTemplateSubitemDropped, (state, action): ITemplatesState => {
    const budgetTemplates = (state.budgetTemplates as IBudgetTemplate<IBudgetTemplateItemGC>[]).map(
      (template) => {
        if (template.id !== state.selectedBudgetTemplateId) {
          return template;
        }
        return {
          ...template,
          template_items: template.template_items.map((item) => {
            if (item.id !== action.templateItemId) {
              return item;
            }
            return {
              ...item,
              subitems: action.templateSubitems.map((subitem, index) => ({
                ...subitem,
                order: index + 1,
              })),
            };
          }),
        };
      },
    );

    return { ...state, budgetTemplates };
  }),

  on(templatesActions.budgetTagTemplateItemDropped, (state, action): ITemplatesState => {
    const budgetTagTemplates = state.budgetTagTemplates.map((template) => {
      if (template.id !== state.selectedBudgetTagTemplateId) {
        return template;
      }
      return {
        ...template,
        tags: action.templateItems.map((item, index) => ({
          ...item,
          order: index + 1,
        })),
      };
    });

    return { ...state, budgetTagTemplates };
  }),

  on(templatesActions.deleteBudgetTemplate, (state, action): ITemplatesState => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplate = budgetTemplates.find((template) => template.id === action.templateId);
    selectedTemplate.is_deleted = true;

    let selectedBudgetTemplateId = state.selectedBudgetTemplateId;
    if (selectedBudgetTemplateId === action.templateId) {
      selectedBudgetTemplateId = null;
    }
    return { ...state, budgetTemplates, selectedBudgetTemplateId };
  }),

  on(templatesActions.removeBudgetTemplateItem, (state, action): ITemplatesState => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy([...state.budgetTemplates]);
    const selectedTemplate = budgetTemplates.find(
      (template) => template.id === state.selectedBudgetTemplateId,
    );
    selectedTemplate.template_items = selectedTemplate.template_items.filter((item) => {
      return item.id !== action.itemId;
    });
    return { ...state, budgetTemplates };
  }),

  on(templatesActions.removeBudgetTemplateSubitem, (state, action): ITemplatesState => {
    if (!state.selectedBudgetTemplateId) {
      return state;
    }
    const budgetTemplates = (state.budgetTemplates as IBudgetTemplate<IBudgetTemplateItemGC>[]).map(
      (template) => {
        if (template.id !== state.selectedBudgetTemplateId) {
          return template;
        }
        return {
          ...template,
          template_items: template.template_items.map((item) => {
            if (item.id !== action.itemId) {
              return item;
            }
            return {
              ...item,
              subitems: item.subitems.filter((subitem) => {
                return subitem.id !== action.subitemId;
              }),
            };
          }),
        };
      },
    );

    return { ...state, budgetTemplates };
  }),

  on(templatesActions.deleteBudgetTagTemplate, (state, action): ITemplatesState => {
    const tagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy([
      ...state.budgetTagTemplates,
    ]);
    const selectedTemplate = tagTemplates.find((template) => template.id === action.templateId);
    selectedTemplate.is_deleted = true;

    let selectedBudgetTagTemplateId = state.selectedBudgetTagTemplateId;
    if (selectedBudgetTagTemplateId === action.templateId) {
      selectedBudgetTagTemplateId = null;
    }
    return { ...state, budgetTagTemplates: tagTemplates, selectedBudgetTagTemplateId };
  }),

  on(templatesActions.removeBudgetTagTemplateItem, (state, action): ITemplatesState => {
    const budgetTagTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy(
      state.budgetTagTemplates,
    );
    const selectedTemplate = budgetTagTemplates.find(
      (template) => template.id === state.selectedBudgetTagTemplateId,
    );

    selectedTemplate.tags = selectedTemplate.tags.filter((item) => {
      return item.id !== action.itemId;
    });

    return { ...state, budgetTagTemplates };
  }),

  on(templatesActions.clearAll, (): ITemplatesState => {
    return {
      ...templatesInitialState,
    };
  }),

  on(templatesActions.budgetTemplatesSaveLoading, (state): ITemplatesState => {
    return {
      ...state,
      autoSaveState: {
        ...state.autoSaveState,
        budgetTemplates: TEMPLATES_AUTO_SAVE_STATES.LOADING,
      },
    };
  }),

  on(templatesActions.budgetTagTemplatesSaveLoading, (state): ITemplatesState => {
    return {
      ...state,
      autoSaveState: {
        ...state.autoSaveState,
        tagTemplates: TEMPLATES_AUTO_SAVE_STATES.LOADING,
      },
    };
  }),

  on(templatesActions.budgetTemplatesSavedSuccessFully, (state, action): ITemplatesState => {
    let selectedBudgetTemplateId: number | string = state.selectedBudgetTemplateId;

    const stateTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy(state.budgetTemplates);
    for (const newTemplate of action.templates) {
      if (newTemplate.is_deleted) {
        continue;
      }
      const stateTemplate = stateTemplates.find(
        (stateTemp) => stateTemp.id === newTemplate.id || stateTemp.id === newTemplate.old_id,
      );
      if (!stateTemplate) {
        continue;
      }

      if (stateTemplate.id === selectedBudgetTemplateId) {
        selectedBudgetTemplateId = newTemplate.id;
      }

      stateTemplate.has_error = false;
      stateTemplate.is_pending = false;
      stateTemplate.id = newTemplate.id;
    }

    let isThereAPendingTemplate = false;
    for (const templateId of action.pendingIds ?? []) {
      const stateTemplate = stateTemplates.find((stateTemp) => stateTemp.id === templateId);
      if (!stateTemplate) {
        continue;
      }
      stateTemplate.is_pending = true;
      isThereAPendingTemplate = true;
    }

    return {
      ...state,
      selectedBudgetTemplateId,
      budgetTemplates: stateTemplates,
      autoSaveState: {
        ...state.autoSaveState,
        budgetTemplates: isThereAPendingTemplate
          ? TEMPLATES_AUTO_SAVE_STATES.PENDING
          : TEMPLATES_AUTO_SAVE_STATES.SAVED_SUCCESSFULLY,
      },
    };
  }),

  on(templatesActions.budgetTemplatesSaveError, (state, action): ITemplatesState => {
    const budgetTemplates: IBudgetTemplate[] = DeepCopyService.deepCopy(state.budgetTemplates).map(
      (template: IBudgetTemplate): IBudgetTemplate => {
        // first reset all templates to not having an error
        template.has_error = false;
        return template;
      },
    );
    for (const templateId of action.templateIds) {
      const template = budgetTemplates.find((t) => t.id === templateId);
      if (template) {
        // then set the ones that errored
        template.has_error = true;
      }
    }

    return {
      ...state,
      budgetTemplates,
      autoSaveState: {
        ...state.autoSaveState,
        budgetTemplates: TEMPLATES_AUTO_SAVE_STATES.ERROR,
      },
    };
  }),

  on(templatesActions.budgetTagsSavedSuccessFully, (state, action): ITemplatesState => {
    let selectedBudgetTagTemplateId: number | string = state.selectedBudgetTagTemplateId;

    const stateTemplates: IBudgetTagTemplate[] = DeepCopyService.deepCopy(state.budgetTagTemplates);
    for (const newTemplate of action.templates) {
      if (newTemplate.is_deleted) {
        continue;
      }
      const stateTemplate = stateTemplates.find(
        (stateTemp) => stateTemp.id === newTemplate.id || stateTemp.id === newTemplate.old_id,
      );
      if (!stateTemplate) {
        continue;
      }

      if (stateTemplate.id === selectedBudgetTagTemplateId) {
        selectedBudgetTagTemplateId = newTemplate.id;
      }

      stateTemplate.has_error = false;
      stateTemplate.is_pending = false;
      stateTemplate.id = newTemplate.id;
    }

    let isThereAPendingTemplate = false;
    for (const templateId of action.pendingIds ?? []) {
      const stateTemplate = stateTemplates.find((stateTemp) => stateTemp.id === templateId);
      if (!stateTemplate) {
        continue;
      }
      stateTemplate.is_pending = true;
      isThereAPendingTemplate = true;
    }

    return {
      ...state,
      selectedBudgetTagTemplateId,
      budgetTagTemplates: stateTemplates,
      autoSaveState: {
        ...state.autoSaveState,
        tagTemplates: isThereAPendingTemplate
          ? TEMPLATES_AUTO_SAVE_STATES.PENDING
          : TEMPLATES_AUTO_SAVE_STATES.SAVED_SUCCESSFULLY,
      },
    };
  }),
);
