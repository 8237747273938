import { createFeatureSelector, createSelector } from '@ngrx/store';
import { primeCommitmentsFeatureKey } from './prime-commitments.reducer';
import { PrimeCommitmentsState } from './prime-commitments.types';

const selectCommitmentsState = createFeatureSelector<PrimeCommitmentsState>(
  primeCommitmentsFeatureKey,
);

const selectAllLineItems = createSelector(selectCommitmentsState, (state) => state.allLineItems);

export const primeCommitmentsSelectors = {
  selectAllLineItems,
};
