import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Hidden file input UI
 * It's role is to open a file input window on command, and emit selected files
 */
@Component({
  selector: 'app-hidden-upload-input',
  standalone: true,
  imports: [CommonModule],
  template: ` <input
    #fileInput
    (change)="onFileInputChange($event)"
    (load)="onFileInputChange($event)"
    [multiple]="allowMultipleFiles"
    [accept]="acceptFileTypes"
    class="upload disp-none"
    type="file"
  />`,
  styles: [],
})
export class UploadHiddenInputComponent {
  @ViewChild('fileInput') fileElement: ElementRef<HTMLInputElement>;
  @Input() acceptFileTypes = '*';
  @Input() allowMultipleFiles = false;
  @Input() resetFilesAfterEmit = false;
  /**
   * Emits selected files as an array of File objects
   */
  @Output() onFilesChange = new EventEmitter();

  /**
   * Emits raw change event as it is received from the file input
   * Useful in cases where you need to access the file input event object directly e.g. profile picture cropper
   */
  @Output() onFilesChangeRaw = new EventEmitter();

  onFileInputChange(file: Event) {
    const files: File[] = Array.from((file.target as HTMLInputElement).files);
    if (files?.length === 0) {
      return;
    }

    this.onFilesChangeRaw.emit(file);
    this.onFilesChange.emit([...files]);

    if (this.resetFilesAfterEmit) {
      // Reset the input value to allow for the same file to be selected again
      this.fileElement.nativeElement.value = '';
    }
  }

  openFileUploadWindow() {
    this.fileElement.nativeElement.click();
  }
}
