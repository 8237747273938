import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { primeContractStatuses } from '../../../../store/prime-commitments/prime-commitments.constants';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { InputCalendarComponent } from '../../../inputs/input-calendar/input-calendar.component';
import { UploadWindowComponent } from '../../../upload/upload-window/upload-window.component';
import { ShortenFileNamePipe } from '../../../../pipes/framework/shorten-file-name.pipe';
import { IPrimeContractModel } from '../../../../store/prime-commitments/prime-commitments.types';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-prime-contract-general-page',
  standalone: true,
  imports: [
    CdkTextareaAutosize,
    DropdownComponent,
    FloatingInputComponent,
    FormsModule,
    InputCalendarComponent,
    UploadWindowComponent,
    ShortenFileNamePipe,
    SimpleButtonComponent,
    NgScrollbar,
  ],
  templateUrl: './prime-contract-general-page.component.html',
  styleUrl: './prime-contract-general-page.component.scss',
})
export class PrimeContractGeneralPageComponent {
  @ViewChild('fileUpload') fileUpload: UploadWindowComponent;
  @ViewChild('generalPage') generalPage: NgForm;

  protected readonly statuses = primeContractStatuses;

  @Input() model: IPrimeContractModel;
  @Output() nextPage = new EventEmitter();

  registerFileUpload($event: FileList) {}

  removeFile(file: any, index: number) {}

  isFormValid() {
    this.generalPage.form.markAllAsTouched();
    return this.generalPage.form.valid;
  }

  goToNextPage() {
    if (!this.isFormValid()) {
      return;
    }
    this.nextPage.emit();
  }
}
