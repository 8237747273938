import { IPermissions } from '../documents/documents.reducer';

export type TemplateId = number | string;

export interface ITemplatesState {
  budgetTemplates: IBudgetTemplate[];
  budgetTagTemplates: IBudgetTagTemplate[];
  selectedBudgetTemplateId: TemplateId;
  selectedBudgetTagTemplateId: TemplateId;
  isLoading: boolean;
  autoSaveState: {
    budgetTemplates: TEMPLATES_AUTO_SAVE_STATES;
    tagTemplates: TEMPLATES_AUTO_SAVE_STATES;
  };
}

export interface ITemplateTeam {
  id: number;
  title: string;
}

export interface IBudgetTemplate<
  T extends IBudgetTemplateItemManager | IBudgetTemplateItemGC = IBudgetTemplateItem,
> {
  id?: TemplateId;
  name: string;
  template_items: T[];
  is_owner: boolean;
  permissions?: Partial<IPermissions>;
  team?: ITemplateTeam;
  team_id?: number;
  is_deleted?: boolean;
  is_trashed?: boolean;
  is_edit?: boolean; // used for frontend only
  has_permissions?: boolean; // used for frontend only
  has_error?: boolean; // used on frontend only
  is_pending?: boolean; // used on frontend only
}

export interface IBudgetTemplateItemBase {
  id: number;
  order: number;
  is_deleted?: boolean;
  permissions?: Partial<IPermissions>;
}

export type IBudgetTemplateItem = IBudgetTemplateItemGC | IBudgetTemplateItemManager;

export interface IBudgetTemplateItemManager extends IBudgetTemplateItemBase {
  account_code: string;
  description: string;
}

export interface IBudgetTemplateItemGC extends IBudgetTemplateItemBase {
  division: string;
  cost_type: string;
  description: string;
  subitems: IBudgetTemplateSubitemGC[];
}

export interface IBudgetTemplateSubitemGC extends IBudgetTemplateItemBase {
  division: string;
  cost_type: string;
  description: string;
  parent_id: number;
}

export interface IBudgetTagTemplate {
  id?: TemplateId;
  name: string;
  is_owner: boolean;
  tags: IBudgetTagItem[];
  permissions?: Partial<IPermissions>;
  team_id?: number;
  is_deleted?: boolean;
  is_edit?: boolean; // used for frontend only
  has_permissions?: boolean; // used for frontend only
  has_error?: boolean; // used on frontend only
  is_pending?: boolean; // used on frontend only
}
export interface IBudgetTagItem {
  id?: number;
  name: string;
  permissions?: Partial<IPermissions>;
  is_deleted?: boolean;
  order?: number; // todo: add on backend too
}

// // todo: these are subject to change
// export interface IGlTemplate {
//   id?: number; // number if existing, uuid that is more like a string if new template
//   name: string;
//   labels: IGlAccountLabel[];
//   permissions?: Partial<IPermissions>;
//   is_deleted?: boolean;
// }
//
// export interface IGlAccountLabel {
//   id?: number;
//   name: string;
//   permissions?: Partial<IPermissions>;
//   is_deleted?: boolean;
// }

export type TemplateViews = 'budget' | 'budget-tag';
export enum TEMPLATE_VIEWS {
  BUDGET = 'budget',
  BUDGET_TAG = 'budget-tag',
}

// export const defaultBudgetTemplate: IBudgetTemplate = {
//   name: '',
//   template_items: [{}],
//   is_trashed: false,
// };

export enum TEMPLATES_AUTO_SAVE_STATES {
  SAVED_SUCCESSFULLY = 'Saved Successfully',
  LOADING = 'Saving',
  PENDING = 'Save pending - please review empty fields',
  ERROR = 'An error occurred while saving one of the templates',
}
