<div class="template-view-wrapper">
  <div class="template-header main-grid-container">
    <div class="template-selector-grid-container">
      <span>Template Name</span>
      <span
        (click)="addTemplateClicked()"
        class="col-start-3 icon-add-block-square text-color_secondary text-[22px]"
      ></span>
    </div>
    <div class="template-lines-grid-container budget-tags mb-0">
      <div class="col-start-3">Budget Tags</div>
      <div class="col-start-4">
        <app-upload-window
          (chosenFiles)="spreadSheetLoaded($event)"
          iconClass="icon-folded-file-upload text-[22px]"
          acceptFileTypes=".xlsx"
          [resetFilesAfterEmit]="true"
        ></app-upload-window>
      </div>
      <div #exportTemplateBtn="cdkOverlayOrigin" cdkOverlayOrigin (click)="isDropdownShown = true" class="max-h-[30px]">
        <span class="icon-download-template text-[22px] text-shade_1 cursor-pointer hover:brightness-[117%]"></span>
      </div>
      <app-options-list-general
        [trigger]="exportTemplateBtn"
        [(show)]="isDropdownShown"
        (close)="isDropdownShown = false"
        [overlayPositions]="defaultDropdownOverlayPositions"
      >
        <div class="bg-white shadow-md p-2">
          <div
            (click)="exportOneTemplate()"
            [ngClass]="{ 'disabled text-shade_3': !selectedTemplate?.tags?.length }"
            class="cursor-pointer text-size_l font-semibold text-shade_1 hover:text-color_accent"
          >
            Export only this template
          </div>
          <div
            (click)="exportAllTemplates()"
            class="cursor-pointer text-size_l font-semibold text-shade_1 hover:text-color_accent"
          >
            Export all templates
          </div>
        </div>
      </app-options-list-general>
    </div>
  </div>

  <div class="main-grid-container flex-1 my-2 min-h-0">
    @if ((templates$ | async).length === 0) {
      <app-general-empty-splash
        class="col-start-1 col-end-3"
        title="There are no templates at the moment."
      ></app-general-empty-splash>
    } @else {
      <app-template-list type="budget-tag"></app-template-list>
      @if (!templateItems?.length) {
        <div>
          <app-general-empty-splash
            class="col-start-1 col-end-8"
            title="There are no template lines to show."
          ></app-general-empty-splash>
        </div>
      } @else {
        <div
          cdkDropList
          cdkScrollable
          cdkDropListOrientation="vertical"
          [cdkDropListData]="templateItems"
          (cdkDropListDropped)="itemDropped($event)"
          class="mr-2 cdk-scrollable"
        >
          @for (templateItem of templateItems; track templateItem.id; let index = $index) {
            <div cdkDrag [cdkDragData]="templateItem" class="item-container">
              <div class="template-lines-grid-container budget-tags">
                <app-template-item-budget-tag
                  [template]="selectedTemplate"
                  [templateItem]="templateItem"
                ></app-template-item-budget-tag>
              </div>
            </div>
          }
        </div>
      }
    }
  </div>
</div>
