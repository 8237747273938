import { Component, inject, Input } from '@angular/core';
import { IBudgetTagItem, IBudgetTagTemplate } from '../../../../../store/templates/templates.types';
import { Store } from '@ngrx/store';
import { templatesActions } from '../../../../../store/templates/templates.actions';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-template-item-budget-tag',
  standalone: true,
  imports: [CdkDragHandle, FloatingInputComponent, NgClass],
  templateUrl: './template-item-budget-tag.component.html',
  styleUrl: './template-item-budget-tag.component.scss',
})
export class TemplateItemBudgetTagComponent {
  @Input({ required: true }) template: IBudgetTagTemplate;
  @Input({ required: true }) templateItem: IBudgetTagItem;
  store = inject(Store);

  minusClicked() {
    this.store.dispatch(
      templatesActions.removeBudgetTagTemplateItem({ itemId: this.templateItem.id }),
    );
  }

  plusClicked() {
    this.store.dispatch(templatesActions.addEmptyBudgetTagTemplateItem());
  }

  fieldChanged(field: 'name', value: string) {
    this.store.dispatch(
      templatesActions.updateBudgetTagTemplateItem({
        templateItemId: this.templateItem.id,
        templateItem: { [field]: value },
      }),
    );
  }
}
