<div
  [ngClass]="[
    dayCount < 0 ? 'negative' : '',
    showText ? 'gap-0.5' : '',
    size === 'big' ? 'min-w-11' : '',
    size === 'default' ? 'min-w-6' : '',
    classes
  ]"
  class="flex no-leading text-[8px] font-bold text-shade_1"
>
  @if (dayCount) {
    {{ Math.abs(dayCount) }}
  } @else {
    {{ '-' }}
  }
  <div class="font-normal">
    @if (showText) {
      {{ dayCount ? 'DAYS' : '' }}
    }
  </div>
</div>
