import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IBudgetTagItem, IBudgetTagTemplate } from '../../../../../store/templates/templates.types';
import { Store } from '@ngrx/store';
import { templateSelectors } from '../../../../../store/templates/templates.selectors';
import { takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { UploadWindowComponent } from '../../../../../framework/upload/upload-window/upload-window.component';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { GeneralEmptySplashComponent } from '../../../../../framework/activities/empty-activities-splash/empty-splash-content/general-empty-splash.component';
import { TemplateListComponent } from '../template-selector/template-list.component';
import { DeepCopyService } from '../../../../../services/deep-copy.service';
import { AsyncPipe, NgClass } from '@angular/common';
import { TemplateItemBudgetTagComponent } from '../template-item-budget-tag/template-item-budget-tag.component';
import { templatesActions } from '../../../../../store/templates/templates.actions';
import { read, utils, WorkBook } from 'xlsx';
import { NotificationsService } from '../../../../../services/notifications.service';
import {
  getDefaultBudgetTagItem,
  getDefaultBudgetTagTemplate,
} from '../../../../../store/templates/templates.constants';
import { CdkOverlayOrigin, CdkScrollable } from '@angular/cdk/overlay';
import { OptionsListGeneralComponent } from '../../../../../framework/overlays/options-list-general/options-list-general.component';
import { defaultDropdownOverlayPositions } from '../../../../../framework/overlays/option-list.constants';
import {
  exportOneTemplate,
  exportTemplates,
  getTemplatesWithSlicedAndUniqueNames,
} from '../templates-export-import-utilities';

@Component({
  selector: 'app-budget-tag-templates',
  templateUrl: './budget-tag-templates.component.html',
  styleUrls: ['./budget-tag-templates.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    UploadWindowComponent,
    CdkDrag,
    GeneralEmptySplashComponent,
    TemplateListComponent,
    CdkDropList,
    AsyncPipe,
    TemplateItemBudgetTagComponent,
    CdkScrollable,
    CdkOverlayOrigin,
    OptionsListGeneralComponent,
    NgClass,
  ],
})
export class BudgetTagTemplatesComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private notif = inject(NotificationsService);
  protected readonly defaultDropdownOverlayPositions = defaultDropdownOverlayPositions;
  isDropdownShown: boolean;

  templates: IBudgetTagTemplate[] = [];
  selectedTemplate: IBudgetTagTemplate;
  templateItems: IBudgetTagItem[] = [];

  selectedTagTemplate$ = this.store.select(templateSelectors.getSelectedBudgetTagTemplate);
  templates$ = this.store.select(templateSelectors.getBudgetTagTemplates);

  isDestroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.selectedTagTemplate$.pipe(takeUntil(this.isDestroyed$)).subscribe((template) => {
      if (template) {
        this.templateItems = DeepCopyService.deepCopy(template.tags);
        this.selectedTemplate = template;
      }
    });

    this.templates$.pipe(takeUntil(this.isDestroyed$)).subscribe((templates) => {
      this.templates = templates;
    });
  }

  addTemplateClicked() {
    this.store.dispatch(templatesActions.addNewTemplateClicked({ view: 'budget-tag' }));
  }

  itemDropped(event: CdkDragDrop<IBudgetTagItem[]>) {
    moveItemInArray(this.templateItems, event.previousIndex, event.currentIndex);
    this.store.dispatch(
      templatesActions.budgetTagTemplateItemDropped({ templateItems: event.container.data }),
    );
  }

  ngOnDestroy(): void {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  async spreadSheetLoaded(files: FileList) {
    const file = files[0];
    if (!file) {
      this.notif.showError("Error: couldn't read the file.");
    }

    const buffer = await file.arrayBuffer();
    const workbook = read(buffer);
    // some Apple stuff, Numbers file exported to XLSX contains a useless sheet with the name "Export Summary"
    const sheetNames = workbook.SheetNames.filter((name) => !name.includes('Export Summary'));

    if (!workbook || !sheetNames.length) {
      this.notif.showError("Error: the selected file doesn't contain any templates.");
      return;
    }

    if (sheetNames.length > 1) {
      const answer = await this.notif.showPopup(
        `This file contains ${sheetNames.length} templates. Do you want to import them?`,
      );
      if (!answer) {
        return;
      }
    }

    for (const sheetName of sheetNames) {
      const budgetTagTemplate = this.getBudgetTagTemplateFromWorkbook(workbook, sheetName);
      if (!budgetTagTemplate) {
        return;
      }

      this.store.dispatch(templatesActions.addBudgetTagTemplateFromFile({ budgetTagTemplate }));
    }
  }

  getBudgetTagTemplateFromWorkbook(
    workbook: WorkBook,
    sheetName: string,
  ): Partial<IBudgetTagTemplate> {
    const parsedData = utils.sheet_to_json<IBudgetTagItem>(workbook.Sheets[sheetName]);
    let tagItems: IBudgetTagItem[] = [];
    try {
      tagItems = this.getBudgetTagItemsFromSheet(parsedData);
    } catch (e) {
      this.notif.showError(e.message);
      return null;
    }

    return {
      ...getDefaultBudgetTagTemplate(),
      name: sheetName,
      is_edit: false,
      tags: tagItems,
    };
  }

  getBudgetTagItemsFromSheet(parsedData: IBudgetTagItem[]): IBudgetTagItem[] {
    return parsedData.map((item) => {
      if (!item.name || !item.order) {
        throw new Error('Error: the selected file is not a valid template.');
      }
      return {
        ...getDefaultBudgetTagItem(),
        name: item.name,
        order: item.order,
      };
    });
  }

  exportOneTemplate() {
    this.isDropdownShown = false;
    try {
      const sheetData = this.convertTagsToSheetData(this.selectedTemplate.tags);
      exportOneTemplate(
        sheetData,
        this.selectedTemplate.name,
        `budget_tag_template_${this.selectedTemplate.name}.xlsx`,
      );
    } catch (e) {
      console.error('Error exporting template', e);
      this.notif.showError('Error exporting the template.');
    }
  }

  exportAllTemplates() {
    this.isDropdownShown = false;

    try {
      const templates = getTemplatesWithSlicedAndUniqueNames(this.templates);
      const sheetData: Partial<IBudgetTagItem>[][] = [];
      const names: string[] = [];
      for (const template of templates) {
        sheetData.push(this.convertTagsToSheetData(template.tags));
        names.push(template.name);
      }
      exportTemplates(sheetData, names, 'budget_tag_templates.xlsx');
    } catch (e) {
      console.error('Error exporting templates', e);
      this.notif.showError('Error exporting the templates.');
    }
  }

  convertTagsToSheetData(tags: IBudgetTagItem[]): Partial<IBudgetTagItem>[] {
    if (!tags?.length) {
      return [];
    }
    return tags.map((tag) => {
      return {
        name: tag.name,
        order: tag.order,
      };
    });
  }
}
