@if (isEditProjectLoading$ | async) {
  <app-page-loading></app-page-loading>
} @else {
  <div class="flex flex-1 h-full flex-col">
    <div class="font-semibold text-sm text-shade_2 pt-6 px-8 pb-10 w-full flex justify-between items-center box-border">
      {{ (isEdit ? 'Edit' : 'Add') + ' Project' }}
    </div>
    <div class="border-b border-solid border-shade_5 -mt-2"></div>

    <form class="flex flex-col h-full p-float-label flex-1 px-7 mt-5 overflow-hidden" #setupForm="ngForm">
      <ng-scrollbar #scrollbar appearance="native" class="std-scrollbar" orientation="vertical">
        <div [ngClass]="{ 'mr-3': scrollbar.isVerticallyScrollable() }">
          <app-floating-input
            [(model)]="model.title"
            label="Title"
            name="title"
            placeholder="Title"
            required
          ></app-floating-input>

          <app-input-calendar
            [(date)]="model.start_date"
            [isDisabled]="false"
            [maxDate]="earliestLineItemDate"
            [minStartDate]="null"
            [required]="true"
            [showIcon]="true"
            iconTooltip="Start date needs to be before or equal the line item's budget or forecast start date!"
            name="start_date"
            placeholder="Project Start Date"
            (dateChange)="startDateChanged($event)"
          ></app-input-calendar>

          <app-dropdown
            (modelChange)="propertiesChanged($event)"
            [(model)]="model.property_id"
            [options]="properties"
            label="Property Name"
            name="property_id"
            optionLabel="name"
            optionValue="id"
            required
          >
          </app-dropdown>

          <app-dropdown
            [(model)]="model.status"
            [options]="projectStatuses"
            label="Project Status"
            name="status"
            optionLabel="name"
            optionValue="id"
            required
          >
            <ng-template #optionContent #selectedContent let-status>
              <div class="flex items-center gap-2">
                @if (status?.name) {
                  <span
                    [ngClass]="{
                      'bg-color_misc': status?.key === PROJECT_STATUS_KEY.IN_PROGRESS,
                      'bg-color_light_green_3': status?.key === PROJECT_STATUS_KEY.COMPLETED,
                      'bg-color_accent_shade_2': status?.key === PROJECT_STATUS_KEY.PLANNED,
                      'bg-color_purple': status?.key === PROJECT_STATUS_KEY.CANCELED,
                      'bg-color_grey_2': status?.key === PROJECT_STATUS_KEY.ARCHIVED,
                      'bg-color_accent': status?.key === PROJECT_STATUS_KEY.BIDDING,
                      'bg-color_accept': status?.key === PROJECT_STATUS_KEY.AWARDED,
                      'bg-color_red_light': status?.key === PROJECT_STATUS_KEY.ON_HOLD,
                      'bg-color_cancel':
                        status?.key === PROJECT_STATUS_KEY.CANCELED || status?.key === PROJECT_STATUS_KEY.DELETED
                    }"
                    class="circle"
                  ></span>
                }
                <span class="capitalize">{{ status?.name }}</span>
              </div>
            </ng-template>
          </app-dropdown>

          <app-floating-input
            [(model)]="model.custom_project_id"
            (inputBlurred)="customIdChanged($event)"
            [disabled]="!customIdEditable || !model.property_id || !model.start_date || hasCommitments"
            label="Project ID"
            placeholder="Project ID"
            name="custom_project_id"
            required
            [minlength]="3"
            [maxlength]="16"
            [iconClass]="{
              position: 'p-input-icon-right',
              icon:
                !model.property_id || !model.start_date || hasCommitments
                  ? ''
                  : customIdEditable
                    ? 'icon-text-revert'
                    : 'icon-text-edit'
            }"
            (iconClick)="onCustomIdIconClick()"
            [pTooltip]="
              hasCommitments
                ? 'This project has commitments, you cannot change the Project ID.'
                : !model.property_id || !model.start_date
                  ? 'Please select property and start date first.'
                  : ''
            "
          ></app-floating-input>

          <app-tag-input
            [(model)]="model.tags"
            label="Project Tags"
            toolTip="You can utilize this field to tag your project with different keyword which you can use to filter and group projects by different categories, ie. CapEx, OpEx, Maintenance...etc."
            name="tags"
          ></app-tag-input>
        </div>
      </ng-scrollbar>
      <div class="flex items-center justify-between mb-7 mt-4 gap-4">
        @if (model?.id) {
          <div (click)="delete()" class="delete-button">Delete</div>
        }
        <div
          (click)="save()"
          [ngClass]="{ 'bg-color_accent ml-auto': !model?.id, 'bg-color_secondary': !!model?.id }"
          class="rounded text-white py-1 px-10 font-semibold text-sm cursor-pointer"
        >
          {{ !!model?.id ? 'Update' : 'Post' }}
        </div>
      </div>
    </form>
  </div>
}
