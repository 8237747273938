import { createReducer, on } from '@ngrx/store';

import { primeSidebarActions } from './prime-commitments.actions';
import { PrimeCommitmentsState } from './prime-commitments.types';
import { initialState } from './prime-commitments.constants';

export const primeCommitmentsFeatureKey = 'prime-commitments';

export const primeCommitmentsReducer = createReducer<PrimeCommitmentsState>(
  initialState,
  on(primeSidebarActions.lineItemsLoaded, (state, action) => {
    return {
      ...state,
      allLineItems: action.lineItems,
    };
  }),
);
