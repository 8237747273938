import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProjectApiService } from '../../../services/project-api.service';
import { ReportingService } from '../../../services/reporting.service';
import { NotificationsService } from '../../../services/notifications.service';
import { CashFlowGraphComponent } from '../../../framework/cash-flow/cash-flow-graph/cash-flow-graph.component';
import { CashflowTableComponent } from '../../../framework/cash-flow/cashflow-table/cashflow-table.component';
import { NgScrollbar } from 'ngx-scrollbar';
import { PageLoadingComponent } from '../../../framework/page-loading/page-loading.component';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ALL_ID, CashflowFilters } from '../../../framework/constants/cashflow.constants';
import { DropdownComponent } from '../../../framework/inputs/dropdown/dropdown.component';
import { GeneralHeaderComponent } from '../../../framework/general-header/general-header.component';
import { Store } from '@ngrx/store';
import { cashflowSelectors } from '../../../store/cashflow/cashflow.selectors';
import { cashflowActions } from '../../../store/cashflow/cashflow.actions';
import { CashFlowService } from '../../../services/cash-flow.service';
import { FiscalService } from '../../../services/fiscal.service';
import dayjs from 'dayjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { viewProjectSelectors } from '../../../store/view-project/view-project.selectors';
import { getSimpleProjects } from '../../../store/projects/projects.selectors';
import { projectActions } from '../../../store/projects/projects.actions';
import { propertiesSelectors } from '../../../store/properties/properties.selector';
import { propertiesActions } from '../../../store/properties/properties.actions';
import { ISimpleProject } from '../projects/projects.interface';
import { IProperty } from '../../../store/properties/properties.interfaces';
import { SearchInputComponent } from '../../../framework/search-input/search-input.component';
import { specialCharReplaceRegexp } from '../../../framework/constants/spend.constants';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss'],
  standalone: true,
  imports: [
    CashFlowGraphComponent,
    CashflowTableComponent,
    NgScrollbar,
    PageLoadingComponent,
    NgClass,
    AsyncPipe,
    DropdownComponent,
    GeneralHeaderComponent,
    NgIf,
    SearchInputComponent,
  ],
})
export class CashFlowComponent implements OnInit, OnDestroy {
  // readonly ALL_PROPERTIES = { id: ALL_ID, name: 'All Properties' };
  // readonly ALL_PROJECTS = { id: ALL_ID, title: 'All Projects', property_id: ALL_ID };

  isLoading$ = this.store.select(cashflowSelectors.isLoading).pipe(
    tap((isLoading) => {
      if (!this.hasLoadedOnce && !isLoading) {
        this.hasLoadedOnce = true;
      }
    }),
  );
  hasLoadedOnce = false;

  fiscalYear$ = this.fiscalService.fiscalYear$;
  fiscalYears$ = this.cashflowService.getFiscalYearFilterOptions$();
  selectedProjectId$ = this.store.select(viewProjectSelectors.selectProjectId);

  filters: CashflowFilters = {
    view: 'projects',
    project_ids: [],
    property_ids: [],
    year: dayjs().year(),
    searchText: null,
  };

  @Input() isViewProject: boolean;
  isDestroyed$ = new Subject<boolean>();
  projects$ = this.store.select(getSimpleProjects);
  properties$ = this.store.select(propertiesSelectors.getAllProperties);

  allProjects: ISimpleProject[] = [];
  projectsFiltered: ISimpleProject[] = [];

  allProperties: Partial<IProperty>[] = [];

  constructor(
    private projectApi: ProjectApiService,
    private reportingService: ReportingService,
    private notif: NotificationsService,
    private store: Store,
    private cashflowService: CashFlowService,
    private fiscalService: FiscalService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(projectActions.loadProjectsSimple({}));
    this.store.dispatch(propertiesActions.loadProperties({}));

    this.projects$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((projects) => !!projects && projects.length > 0),
        take(1),
      )
      .subscribe((projects) => {
        this.allProjects = [...projects];
        this.projectsFiltered = [...this.allProjects];
        // this.filters.project_ids = this.projectsFiltered.map((p) => p.id);
      });

    this.properties$
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((projects) => !!projects && projects.length > 0),
        take(1),
      )
      .subscribe((properties) => {
        this.allProperties = [...properties].toSorted((a, b) => {
          const aField = a.name.replace(specialCharReplaceRegexp, '');
          const bField = b.name.replace(specialCharReplaceRegexp, '');
          return aField.localeCompare(bField);
        });
        // this.filters.property_ids = this.allProperties.map((p) => p.id);
      });

    combineLatest([
      this.fiscalYear$.pipe(
        filter((v) => !!v),
        take(1),
      ),
      this.selectedProjectId$,
    ])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(([year, projectId]) => {
        if (!this.isViewProject) {
          this.filters = {
            ...this.filters,
            view: 'projects',
            year,
          };
        } else {
          this.filters = {
            view: 'budget_lines',
            project_ids: [projectId],
            property_ids: [],
            year,
          };
        }
        this.store.dispatch(cashflowActions.filtersChanged({ filters: { ...this.filters } }));
      });
  }

  onSearchTextChange(text: string) {
    this.filters.searchText = text || undefined;
    this.store.dispatch(cashflowActions.filtersChanged({ filters: { ...this.filters } }));
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  onFiscalYearChange(year: number) {
    this.filters.year = year;
    this.store.dispatch(cashflowActions.filtersChanged({ filters: { ...this.filters } }));
  }

  onProjectChange(projectIds: number[]) {
    this.store.dispatch(cashflowActions.filtersChanged({ filters: { ...this.filters } }));
  }

  onPropertyChange(propertyIds: number[]) {
    if (!propertyIds) {
      return;
    }
    if (!propertyIds.length) {
      // this.filters.project_ids = this.allProjects.map((p) => p.id);
      this.projectsFiltered = [...this.allProjects];
    } else {
      // filter shown projects by selected property
      this.projectsFiltered = this.allProjects.filter(
        (project) => propertyIds.includes(project.property_id) || project.id === ALL_ID,
      );

      // remove project_ids that are not in the filtered projects
      this.filters.project_ids = this.filters.project_ids.filter((id) =>
        this.projectsFiltered.map((p) => p.id).includes(id),
      );
    }

    this.store.dispatch(cashflowActions.filtersChanged({ filters: { ...this.filters } }));
  }
}
