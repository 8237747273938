import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { FormsModule, NgForm } from '@angular/forms';
import { IPrimeContractModel } from 'src/app/store/prime-commitments/prime-commitments.types';
import { defaultMarkupItem } from '../../../../store/prime-commitments/prime-commitments.constants';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-prime-change-order-markups',
  standalone: true,
  imports: [
    FloatingInputComponent,
    AddRemoveButtonsComponent,
    SimpleButtonComponent,
    DropdownComponent,
    FormsModule,
    NgScrollbar,
  ],
  templateUrl: './prime-change-order-markups.component.html',
  styleUrl: './prime-change-order-markups.component.scss',
})
export class PrimeChangeOrderMarkupsComponent {
  @ViewChild('COMarkups') markupsForm: NgForm;

  @Input() model: IPrimeContractModel;
  @Output() modelChange = new EventEmitter<IPrimeContractModel>();

  @Output() nextPage = new EventEmitter<void>();

  onMarkupChange(id: number, index) {
    const selectedLineItem = this.model.lineItems.find((i) => i.id === id);
    this.model.lineItemMarkup[index].name = selectedLineItem.name;
    this.model.lineItemMarkup[index].division = selectedLineItem.division;
    this.model.lineItemMarkup[index].cost_type = selectedLineItem.cost_type;
    this.model.lineItemMarkup[index].id = id;
  }

  addDefaultItem() {
    this.model.lineItemMarkup.push(defaultMarkupItem(0));
  }

  removeItem(index: number) {
    this.model.lineItemMarkup.splice(index, 1);
    this.model.lineItemMarkup = this.model.lineItemMarkup.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
  }

  addItem(index: number) {
    this.model.lineItemMarkup.splice(index + 1, 0, defaultMarkupItem());
    this.model.lineItemMarkup = this.model.lineItemMarkup.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
  }

  isFormValid() {
    this.markupsForm.form.markAllAsTouched();
    return this.markupsForm.form.valid;
  }

  goToNextPage() {
    this.markupsForm.form.markAllAsTouched();
    if (!this.isFormValid()) {
      return;
    }
    this.nextPage.emit();
  }
}
