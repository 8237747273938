import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { UploadHiddenInputComponent } from '../upload-hidden-input/upload-hidden-input.component';
import {
  CUSTOM_OVERLAY_VIEWS,
  CustomOverlayService,
} from '../../../services/custom-overlay.service';
import { first, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

/**
 * Component that opens a file upload window on click
 * Can return selected files as an array of File objects, or as a raw event, or cropped image
 */
@Component({
  selector: 'app-upload-window',
  templateUrl: './upload-window.component.html',
  styleUrls: ['./upload-window.component.scss'],
  standalone: true,
  imports: [MatTooltipModule, NgClass, UploadHiddenInputComponent],
})
export class UploadWindowComponent implements OnDestroy {
  @ViewChild('hiddenFileInput') hiddenFileInput: UploadHiddenInputComponent;
  @Input() disabled = false;
  @Input() allowMultipleFiles = false;
  @Input() hideUploadIcon = false;
  @Input() iconClass = 'icon-upload';
  @Input() acceptFileTypes: string;
  @Input() resetFilesAfterEmit = false;
  @Output() chosenFiles: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() chosenFilesRaw: EventEmitter<any> = new EventEmitter<any>();
  @Output() croppedImage: EventEmitter<Blob> = new EventEmitter<Blob>();

  private isDestroyed$ = new Subject<void>();
  subscription: Subscription;

  constructor(private overlayService: CustomOverlayService) {}

  ngOnDestroy() {
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  registerFileAdded(event: FileList) {
    this.chosenFiles.emit(event);
  }

  registerFileAddedRaw($event: any) {
    this.chosenFilesRaw.emit($event);
  }

  openWindow() {
    this.hiddenFileInput.openFileUploadWindow();
  }

  openCropper(files: FileList) {
    if (!files?.length) {
      return;
    }

    const file = files[0];
    this.overlayService.openOverlay(
      {
        position: 'global',
        centerHorizontally: true,
        centerVertically: true,
        closeOnBackdropClick: false,
        backdropClass: undefined,
      },
      file,
      CUSTOM_OVERLAY_VIEWS.CROPPER,
    );

    if (this.subscription?.closed === false) {
      this.subscription?.unsubscribe();
    }

    this.subscription = this.overlayService.outputData$
      .pipe(takeUntil(this.isDestroyed$), first())
      .subscribe((blob: Blob) => {
        if (!blob) {
          return;
        }

        this.croppedImage.emit(blob);
        this.overlayService.closeOverlay();
      });
  }
}
