<ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
  <div class="flex my-2.5 mx-7 flex-col flex-1">
    <form class="h-full" #COMarkups="ngForm" role="form">
      @for (lineItem of model.lineItemMarkup; track lineItem.id + '_' + index; let index = $index) {
        <div class="flex items-center gap-2">
          <app-dropdown
            required
            [optionLabel]="null"
            [optionValue]="'id'"
            [model]="lineItem.id"
            (modelChange)="onMarkupChange($event, index)"
            [placeholder]="'Default Markup ' + index"
            [label]="'Default Markup ' + index"
            [name]="'line_item_retainage_' + lineItem.id"
            class="w-full max-w-40"
            [options]="model.lineItems"
          >
            <ng-template #selectedContent #optionContent let-option>
              <div class="flex flex-col">
                <div class="capitalize text-color_primary text-medium">{{ option.name }}</div>
                <div class="capitalize text-color_primary font-normal">
                  {{ option?.division }} {{ option?.cost_type }}
                </div>
              </div>
            </ng-template>
          </app-dropdown>

          <app-floating-input
            required
            class="w-full overflow-hidden"
            [(model)]="lineItem.value"
            [name]="'retainage_' + lineItem.id"
            label="Rate %"
            placeholder="Rate %"
            type="number"
          ></app-floating-input>

          <app-add-remove-buttons
            class="mt-3.5"
            [disabledAdd]="model.lineItems.length === model.lineItemMarkup.length"
            (removeItem)="removeItem(index)"
            (addItem)="addItem(index)"
          >
          </app-add-remove-buttons>
        </div>
      } @empty {
        <app-simple-button
          [wrapperClass]="{ 'w-full': true }"
          buttonStyle="color_secondary_border"
          (click)="addDefaultItem()"
          >Add Line Items
        </app-simple-button>
      }
    </form>
  </div>
</ng-scrollbar>
<div class="flex my-6 mx-7 justify-end gap-4">
  <app-simple-button (click)="goToNextPage()">Next</app-simple-button>
</div>
