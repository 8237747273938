import { EventEmitter, Injectable } from '@angular/core';
import { SPEND_ACTIONS } from '../framework/constants/budget.constants';
import { BehaviorSubject } from 'rxjs';
import { SPEND_DISTRIBUTION_STATE } from '../framework/constants/spend.constants';

@Injectable({
  providedIn: 'root',
})
export class SpendViewProjectStateService {
  // not used anymore
  // budgetType = SPEND_TYPES.BUDGET;
  stateEvents = new EventEmitter<SPEND_ACTIONS>();
  currentState = new BehaviorSubject<SPEND_DISTRIBUTION_STATE>(SPEND_DISTRIBUTION_STATE.DEFAULT);

  constructor() {}

  close() {
    if (this.currentState.value === SPEND_DISTRIBUTION_STATE.SHOW_SPREADSHEET) {
      this.closeSheet();
    } else {
      this.closeOverlay();
    }
  }

  closeSheet(spendDistributionState: SPEND_DISTRIBUTION_STATE = SPEND_DISTRIBUTION_STATE.DEFAULT) {
    this.stateEvents.next(SPEND_ACTIONS.CLOSE_SHEETS);
    this.currentState.next(spendDistributionState);
  }

  update() {
    this.stateEvents.next(SPEND_ACTIONS.UPDATE);
  }

  // not used anymore
  // changeBudgetType() {
  //   this.stateEvents.next(SPEND_ACTIONS.CHANGE_BUDGET_TYPE);
  // }

  closeOverlay() {
    this.stateEvents.next(SPEND_ACTIONS.CLOSE);
  }

  save() {
    this.stateEvents.next(SPEND_ACTIONS.SAVE);
  }

  refresh() {
    this.stateEvents.next(SPEND_ACTIONS.REFRESH);
  }
}
