import { Pipe, PipeTransform } from '@angular/core';
import { OPTIONS } from '../../framework/constants/options-list.constants';
import { IPermissions } from '../../store/documents/documents.reducer';

import { BasePermissionsPipe } from './base-permissions.pipe';

@Pipe({
  name: 'optionPermissions',
  standalone: true,
})
export class OptionsPermissionsPipe extends BasePermissionsPipe implements PipeTransform {
  transform(permissions: Partial<IPermissions>): OPTIONS[] {
    if (!permissions) {
      return [];
    }
    const allPermissions: OPTIONS[] = [];

    this.permissionLiteralToOptions.forEach((permissionLiteralOption) => {
      const option = this.findOption(permissionLiteralOption.value);
      const isPermissionAllowed: 1 | 0 = permissions[permissionLiteralOption.permission]; // will find specific permission 1 or 0
      if (isPermissionAllowed && option) {
        allPermissions.push(option.value);
      }
    });
    return allPermissions;
  }
}
