import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';
import { redirectProjectListing } from './redirect.helper';

export const canActivateProjectsManagerGuard: CanActivateFn = () => {
  const currentUserService = inject(CurrentUserService);
  const isManager = currentUserService.isManager;

  if (!isManager) {
    redirectProjectListing();
    return false;
  }
  return true;
};
