<app-hidden-upload-input
  #hiddenFileInput
  (onFilesChange)="registerFileAdded($event)"
  (onFilesChangeRaw)="registerFileAddedRaw($event)"
  [allowMultipleFiles]="allowMultipleFiles"
  [acceptFileTypes]="acceptFileTypes"
  [resetFilesAfterEmit]="resetFilesAfterEmit"
></app-hidden-upload-input>

<div
  (click)="hiddenFileInput.openFileUploadWindow()"
  [ngClass]="{ 'disabled color-cancel': disabled, 'disp-none': hideUploadIcon }"
  matTooltip="Upload File"
  class="flex align-baseline justify-center cursor-pointer text-color_secondary max-h-[30px]"
>
  <span [ngClass]="[iconClass]"></span>
</div>

<ng-content></ng-content>
