import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { documentsReducer } from '../documents/documents.reducer';
import { projectDocumentsReducer } from '../projectDocuments/projectDocuments.reducer';
import { spendReducer } from '../spend/spend.reducer';
import { templatesReducer } from '../templates/templates.reducer';

export interface AppState {
  // todo update the types
  documents: any;
  projectDocuments: any;
  spend: any;
  templates: any;
}

export const reducers: ActionReducerMap<AppState> = {
  documents: documentsReducer,
  projectDocuments: projectDocumentsReducer,
  spend: spendReducer,
  templates: templatesReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
