<div class="sidebar-wrapper">
  <app-sidebar-header (backButtonClicked)="goToPreviousPage()" [showBackButton]="currentPage !== 1">
    <div smallTitle>{{ model.id ? 'Edit' : 'Add' }} Prime Contract</div>
    @switch (currentPage) {
      @case (1) {
        <div mainTitle>General Information</div>
      }
      @case (2) {
        <div class="flex justify-between" mainTitle>
          @if (showPrimeTemplateUploadPage) {
            <span>Upload Prime Template</span>
          } @else {
            <span>Prime Contract values</span>
            <span
              (click)="addValuesFromLineItems()"
              class="text-color_secondary icon-download_budget ml-auto mr-3.5 text-size_icon_bigger cursor-pointer"
              pTooltip="Copy the budget dollars respective to each budget line."
            ></span>
            <span
              class="text-color_secondary icon-folded-file-upload text-size_icon_bigger cursor-pointer"
              pTooltip="Upload an excel file to import the budget values."
              (click)="changeViewToUploadPage()"
            ></span>
          }
        </div>
      }
      @case (3) {
        <div mainTitle>Change Order Markups</div>
      }
      @case (4) {
        <div mainTitle>Retainage Settings</div>
      }
    }
  </app-sidebar-header>
  <div class="main-content m-0 flex flex-col">
    @switch (currentPage) {
      @case (1) {
        <app-prime-contract-general-page [model]="model" (nextPage)="goToNextPage()"></app-prime-contract-general-page>
      }
      @case (2) {
        @if (showPrimeTemplateUploadPage) {
          <app-prime-contract-upload-template
            (parsedData)="replaceDataWithTemplate($event)"
          ></app-prime-contract-upload-template>
        } @else {
          <app-prime-contract-values (nextPage)="goToNextPage()" [(model)]="model"></app-prime-contract-values>
        }
      }
      @case (3) {
        <app-prime-change-order-markups [(model)]="model" (nextPage)="goToNextPage()"></app-prime-change-order-markups>
      }
      @case (4) {
        <app-prime-invoice-retainage [(model)]="model" (saveContract)="saveContract()"></app-prime-invoice-retainage>
      }
    }
  </div>
</div>
