import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { AddRemoveButtonsComponent } from '../../../buttons/add-remove-buttons/add-remove-buttons.component';
import { FloatingInputComponent } from '../../../inputs/floating-input/floating-input.component';
import { DropdownComponent } from '../../../inputs/dropdown/dropdown.component';
import { SearchInputComponent } from '../../../search-input/search-input.component';
import { FormsModule, NgForm } from '@angular/forms';
import { filter, map, take } from 'rxjs/operators';
import {
  IPrimeContractModel,
  IPrimeLineItem,
  IPrimeLineItemResponse,
} from '../../../../store/prime-commitments/prime-commitments.types';
import { Observable } from 'rxjs';
import { primeCommitmentsSelectors } from '../../../../store/prime-commitments/prime-commtiments.selectors';
import { Store } from '@ngrx/store';
import { AsyncPipe } from '@angular/common';
import { defaultPrimeLineItem } from '../../../../store/prime-commitments/prime-commitments.constants';
import { SimpleButtonComponent } from '../../../buttons/simple-medium-button/simple-button.component';
import { MoneyPipe } from '../../../../pipes/framework/money-short.pipe';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-prime-contract-values',
  standalone: true,
  imports: [
    AddRemoveButtonsComponent,
    FloatingInputComponent,
    DropdownComponent,
    SearchInputComponent,
    FormsModule,
    AsyncPipe,
    SimpleButtonComponent,
    MoneyPipe,
    NgScrollbar,
  ],
  templateUrl: './prime-contract-values.component.html',
  styleUrl: './prime-contract-values.component.scss',
})
export class PrimeContractValuesComponent {
  @ViewChild('lineItemsPage') primeValuesForm: NgForm;

  @Input() model: IPrimeContractModel;
  @Output() modelChange = new EventEmitter<IPrimeContractModel>();
  @Output() nextPage = new EventEmitter<void>();

  private readonly store = inject(Store);

  allLineItems$: Observable<IPrimeLineItemResponse[]> = this.store.select(
    primeCommitmentsSelectors.selectAllLineItems,
  );

  searchValue = '';

  onSearch(text: string) {
    this.searchValue = text;
  }

  onLineItemChange(id: number, index: number) {
    this.allLineItems$
      .pipe(
        map((items) => items.find((item) => item.id === id)),
        filter((item) => !!item),
        take(1),
      )
      .subscribe((item) => {
        this.model.lineItems[index].name = item.name;
        this.model.lineItems[index].division = item.division;
        this.model.lineItems[index].cost_type = item.cost_type;
        this.model.lineItems[index].id = id;
      });
  }

  get filteredLineItems(): IPrimeLineItem[] {
    return this.model.lineItems.filter((item) => {
      return (
        item.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.division.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        item.cost_type.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    });
  }

  removeItem(index: number) {
    if (this.model.lineItems.length === 1) {
      return;
    }

    const lineItemId = this.model.lineItems[index].id;
    this.model.lineItems.splice(index, 1);
    const markupIndex = this.model.lineItemMarkup.findIndex((item) => item.id === lineItemId);
    const retainageIndex = this.model.retainageSettings.findIndex((item) => item.id === lineItemId);
    if (markupIndex !== -1) {
      this.model.lineItemMarkup.splice(markupIndex, 1);
    }

    if (retainageIndex !== -1) {
      this.model.retainageSettings.splice(retainageIndex, 1);
    }

    this.reOrderLineItems();
  }

  addItem(index: number) {
    this.model.lineItems.splice(index + 1, 0, defaultPrimeLineItem(index + 1));
    this.reOrderLineItems();
  }

  reOrderLineItems() {
    this.model.lineItems = this.model.lineItems.map((item, index) => {
      return {
        ...item,
        index,
      };
    });
  }

  get totals() {
    return this.model.lineItems.reduce((acc, curr) => {
      return Number(acc) + Number(curr.value);
    }, 0);
  }

  isFormValid() {
    this.primeValuesForm.form.markAllAsTouched();
    return this.primeValuesForm.form.valid;
  }

  goToNextPage() {
    if (!this.isFormValid()) {
      return;
    }

    this.nextPage.emit();
  }
}
