import { inject, Injectable } from '@angular/core';
import { ITEMS_SUMMARY, PRIME_CONTRACTS, REST_PROJECTS } from '../restApi/RestRoutes';
import { RestRequestService } from '../restApi/rest-request.service';
import { Observable } from 'rxjs';
import {
  IPrimeContractModel,
  IPrimeContractModelResponse,
  IPrimeLineItemResponse,
} from '../store/prime-commitments/prime-commitments.types';

@Injectable({
  providedIn: 'root',
})
export class PrimeCommitmentsApiService {
  private readonly rest = inject(RestRequestService);

  /**
   * Load all line items for prime contracts
   */
  getLineItems(projectId: number): Observable<IPrimeLineItemResponse[]> {
    return this.rest.getWithObservable(`${REST_PROJECTS}/${projectId}/${ITEMS_SUMMARY}`, {}, {});
  }

  /**
   * @param projectId
   * @param primeContract
   * Create a prime contract
   */
  createPrimeContract(projectId: number, primeContract: IPrimeContractModel) {
    const body = this.convertToPrimeContractPayload(projectId, primeContract);
    return this.rest.postWithObservable(`${PRIME_CONTRACTS}`, body);
  }

  /**
   * Update a prime contract
   * @param projectId
   * @param primeContract
   */
  updatePrimeContract(projectId: number, primeContract: IPrimeContractModel) {
    const body = this.convertToPrimeContractPayload(projectId, primeContract);
    return this.rest.putWithObservable(`${PRIME_CONTRACTS}/${primeContract.id}`, body);
  }

  /**
   * Convert the prime contract model to a payload, which can be sent to the server
   * @param projectId
   * @param primeContract
   */
  convertToPrimeContractPayload(projectId: number, primeContract: IPrimeContractModel) {
    return {
      project_id: projectId,
      title: primeContract.name,
      owner_name: primeContract.owner_name,
      date: primeContract.date,
      status: primeContract.status,
      description: primeContract.note,
      default_invoice_retainage_rate: primeContract.defaultInvoiceRetainageRate,
      contract_values: primeContract.lineItems.map((item) => {
        return {
          item_id: item.id,
          value: item.value,
          index: item.index,
          change_order_markup_rate:
            primeContract.lineItemMarkup.find((lineItem) => lineItem.id === item.id)?.value ?? null,
          invoice_retainage_rate:
            primeContract.retainageSettings.find((lineItem) => lineItem.id === item.id)?.value ??
            null,
        };
      }),
    };
  }

  /**
   * Load a prime contract by id, for ex. used for editing in the sidebar
   * @param loadContractById
   */
  loadPrimeContract(loadContractById: number): Observable<IPrimeContractModelResponse> {
    return this.rest.getWithObservable(`${PRIME_CONTRACTS}/${loadContractById}`);
  }

  /**
   * Delete a prime contract
   * @param primeContractId
   */
  deletePrimeContract(primeContractId: number) {
    return this.rest.delWithObservable(`${PRIME_CONTRACTS}/${primeContractId}`);
  }
}
