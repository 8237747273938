import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NotificationsService } from '../../../../../services/notifications.service';
import {
  IBudgetTemplate,
  IBudgetTemplateItem,
  IBudgetTemplateItemGC,
  IBudgetTemplateItemManager,
  IBudgetTemplateSubitemGC,
} from '../../../../../store/templates/templates.types';
import { templateSelectors } from '../../../../../store/templates/templates.selectors';
import { templatesActions } from '../../../../../store/templates/templates.actions';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { CdkOverlayOrigin, CdkScrollable, ConnectedPosition } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';
import { defaultDropdownOverlayPositions } from '../../../../../framework/overlays/option-list.constants';
import { FormsModule } from '@angular/forms';
import { UploadWindowComponent } from '../../../../../framework/upload/upload-window/upload-window.component';
import { TemplateListComponent } from '../template-selector/template-list.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { GeneralEmptySplashComponent } from '../../../../../framework/activities/empty-activities-splash/empty-splash-content/general-empty-splash.component';
import { CurrentUserService } from '../../../../../services/current-user.service';
import {
  getDefaultBudgetTemplateItem,
  getDefaultProjectBudgetTemplate,
  isManagerTemplateItem,
} from '../../../../../store/templates/templates.constants';
import { TemplateItemProjectBudgetComponent } from '../template-item/template-item-project-budget.component';
import { TemplateSubitemComponent } from '../template-subitem/template-subitem.component';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeepCopyService } from '../../../../../services/deep-copy.service';
import { read, utils, WorkBook, WorkSheet } from 'xlsx';
import { OptionsListGeneralComponent } from '../../../../../framework/overlays/options-list-general/options-list-general.component';
import {
  exportOneTemplate,
  exportTemplates,
  getTemplatesWithSlicedAndUniqueNames,
} from '../templates-export-import-utilities';
import { SimpleButtonComponent } from '../../../../../framework/buttons/simple-medium-button/simple-button.component';

@Component({
  selector: 'app-project-budget-templates',
  templateUrl: './project-budget-templates.component.html',
  styleUrls: ['./project-budget-templates.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    UploadWindowComponent,
    TemplateListComponent,
    AsyncPipe,
    GeneralEmptySplashComponent,
    NgClass,
    TemplateItemProjectBudgetComponent,
    TemplateSubitemComponent,
    CdkDropList,
    CdkDrag,
    CdkScrollable,
    OptionsListGeneralComponent,
    CdkOverlayOrigin,
    SimpleButtonComponent,
  ],
})
export class ProjectBudgetTemplatesComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private notif = inject(NotificationsService);
  userService = inject(CurrentUserService);

  @ViewChildren('trigger') trigger: QueryList<CdkOverlayOrigin>;
  @Output() gotToTagTemplates = new EventEmitter();
  selectedTemplate$: Observable<IBudgetTemplate> = this.store.select(
    templateSelectors.getSelectedBudgetTemplate,
  );
  templates$: Observable<IBudgetTemplate[]> = this.store.select(
    templateSelectors.getBudgetTemplates,
  );

  template: IBudgetTemplate;
  templates: IBudgetTemplate[] = [];
  defaultDropdownOverlayPositions: ConnectedPosition[] = defaultDropdownOverlayPositions;

  templateItems: IBudgetTemplateItem[] = [];

  isDestroyed$ = new Subject<boolean>();
  isDropdownShown = false;

  ngOnInit(): void {
    this.selectedTemplate$.pipe(takeUntil(this.isDestroyed$)).subscribe((selectedTemplate) => {
      this.templateItems = DeepCopyService.deepCopy(selectedTemplate.template_items);
      this.template = selectedTemplate;
    });

    this.templates$.pipe(takeUntil(this.isDestroyed$)).subscribe((templates) => {
      this.templates = templates;
    });
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  addTemplateItem() {
    this.store.dispatch(
      templatesActions.addEmptyBudgetTemplateItem({ isManager: this.userService.isManager }),
    );
  }

  addTemplateClicked() {
    this.store.dispatch(templatesActions.addNewTemplateClicked({ view: 'budget' }));
  }

  public isGCTemplate(
    template: IBudgetTemplateItemGC | IBudgetTemplateItemManager,
  ): template is IBudgetTemplateItemGC {
    return (template as IBudgetTemplateItemGC).subitems !== undefined;
  }

  itemDropped(event: CdkDragDrop<IBudgetTemplateItem[]>) {
    moveItemInArray(this.templateItems, event.previousIndex, event.currentIndex);
    this.store.dispatch(
      templatesActions.budgetTemplateItemDropped({ templateItems: event.container.data }),
    );
  }

  subitemDropped(
    event: CdkDragDrop<IBudgetTemplateSubitemGC[]>,
    templateItem: IBudgetTemplateItem,
  ) {
    console.log(event);
    if (this.isGCTemplate(templateItem)) {
      moveItemInArray(templateItem.subitems, event.previousIndex, event.currentIndex);

      this.store.dispatch(
        templatesActions.budgetTemplateSubitemDropped({
          templateItemId: event.item.data.parent_id,
          templateSubitems: event.container.data,
        }),
      );
    }
  }

  async spreadSheetLoaded(files: FileList) {
    let errorFlag = false;

    const file = files[0];
    if (!file) {
      this.notif.showError("Error: couldn't read the file.");
    }

    const buffer = await file.arrayBuffer();
    const workbook = read(buffer);
    // some Apple stuff, Numbers file exported to XLSX contains a useless sheet with the name "Export Summary"
    const sheetNames = workbook.SheetNames.filter((name) => !name.includes('Export Summary'));

    if (!workbook || !sheetNames.length) {
      this.notif.showError("Error: the selected file doesn't contain any templates.");
      return;
    }

    if (sheetNames.length > 1) {
      const answer = await this.notif.showPopup(
        `This file contains ${sheetNames.length} templates. Do you want to import them?`,
      );
      if (!answer) {
        return;
      }
    }

    for (const sheetName of sheetNames) {
      const { budgetTemplate, budgetTemplateError } = this.getBudgetTemplateFromWorkbook(
        workbook,
        sheetName,
      );
      errorFlag = errorFlag || budgetTemplateError;

      if (!budgetTemplate) {
        continue;
      }
      this.store.dispatch(templatesActions.addBudgetTemplateFromFile({ budgetTemplate }));
    }

    console.log('errorFlag', errorFlag);
    if (errorFlag) {
      setTimeout(() => {
        this.notif.showError("Import finished with errors. Please check the input file's format.");
      }, 440);
    }
  }

  getBudgetTemplateFromWorkbook(workbook: WorkBook, sheetName: string) {
    let errorFlag = false;
    let templateItems: IBudgetTemplateItem[];
    const { mainItems, subItems } = this.getItemsFromSheet(workbook.Sheets[sheetName]);
    if (!mainItems.length) {
      errorFlag = true;
    }

    const { templateItems: templateItemsWithMainItems, errorFlag: errorInMainItems } =
      this.parseMainItems(mainItems);
    errorFlag = errorFlag || errorInMainItems;
    templateItems = templateItemsWithMainItems;

    if (this.userService.isGeneralContractor) {
      const { templateItems: templateItemsWithSubitems, errorFlag: errorInSubitems } =
        this.parseSubitems(subItems, templateItems);
      templateItems = templateItemsWithSubitems;
      errorFlag = errorFlag || errorInSubitems;
    }

    const budgetTemplate: IBudgetTemplate = {
      ...getDefaultProjectBudgetTemplate(this.userService.isManager),
      name: sheetName,
      is_edit: false,
      template_items: templateItems,
    };

    errorFlag = errorFlag || !budgetTemplate.template_items?.length;
    console.log('errorFlag parse workbook', errorFlag);

    return {
      budgetTemplate: budgetTemplate?.template_items?.length > 0 ? budgetTemplate : null,
      budgetTemplateError: errorFlag,
    };
  }

  getItemsFromSheet(sheet: WorkSheet) {
    const parsedSheet = utils.sheet_to_json<IBudgetTemplateItem>(sheet);
    // if order is an integer, it is a main (parent) item
    const mainItems = parsedSheet.filter((item) => Number.isInteger(Number(item.order)));
    // if order is not an integer, it is a subitem with an order like 1.1, 1.2, 1.3 etc.
    const subItems = parsedSheet.filter(
      (item) => !Number.isInteger(Number(item.order)),
    ) as unknown as IBudgetTemplateItemGC[];

    return { mainItems, subItems };
  }

  parseMainItems(mainItems: IBudgetTemplateItem[]) {
    let errorFlag = false;
    let index = 1;

    const templateItems = mainItems.map((item) => {
      const templateItem: IBudgetTemplateItem = {
        ...getDefaultBudgetTemplateItem(this.userService.isManager),
        description: item.description,
        order: item.order ?? index++,
        subitems: [],
      };

      // these are required fields at the moment, this may change
      if (!item.description) {
        errorFlag = true;
        return null;
      }

      // these are NOT required fields at the moment, this may change
      if (isManagerTemplateItem(item)) {
        (templateItem as IBudgetTemplateItemManager).account_code = item.account_code;
      } else {
        (templateItem as IBudgetTemplateItemGC).division = item.division;
        (templateItem as IBudgetTemplateItemGC).cost_type = item.cost_type;
      }

      return templateItem;
    });

    return {
      templateItems: templateItems.filter((item) => !!item),
      errorFlag,
    };
  }

  parseSubitems(subItems: IBudgetTemplateItemGC[], templateItems: IBudgetTemplateItem[]) {
    let errorFlag = false;
    let index = 1;

    for (const subitem of subItems) {
      const originalOrder = subitem.order as unknown as string;
      const parentOrder = originalOrder.split('.')[0];
      const subitemOrder = originalOrder.split('.')[1];
      const parent = templateItems.find(
        (item) => item.order === Number(parentOrder),
      ) as IBudgetTemplateItemGC;

      if (!parent || !subitemOrder) {
        errorFlag = true;
        continue;
      }

      // description and order are required fields at the moment, this may change
      if (!subitem.description || !subitemOrder) {
        errorFlag = true;
        continue;
      }

      parent.subitems.push({
        ...getDefaultBudgetTemplateItem(this.userService.isManager),
        parent_id: parent.id,
        order: subitemOrder ? Number(subitemOrder) : index++,
        description: subitem.description ?? '',
        division: subitem.division ?? '',
        cost_type: subitem.cost_type ?? '',
      });
    }

    return {
      templateItems,
      errorFlag,
    };
  }

  exportOneTemplate() {
    this.isDropdownShown = false;
    if (!this.templateItems?.length) {
      this.notif.showError('There is no template to export.');
      return;
    }

    try {
      const sheetData = this.convertTemplateItemsToSheetData(this.templateItems);
      exportOneTemplate(
        sheetData,
        this.template?.name,
        `budget_template_${this.template?.name}.xlsx`,
      );
    } catch (e) {
      this.notif.showError('Error: could not export the template.');
    }
  }

  exportAllTemplates() {
    this.isDropdownShown = false;
    if (!this.templates?.length) {
      this.notif.showError('There is no template to export.');
      return;
    }

    try {
      const templates = getTemplatesWithSlicedAndUniqueNames(this.templates);
      const sheetData: Partial<IBudgetTemplateItem>[][] = [];
      const names: string[] = [];
      for (const template of templates) {
        sheetData.push(this.convertTemplateItemsToSheetData(template.template_items));
        names.push(template.name);
      }
      exportTemplates(sheetData, names, 'budget_templates.xlsx');
    } catch (e) {
      this.notif.showError('Error: could not export the templates.');
    }
  }

  convertTemplateItemsToSheetData(
    templateItems: IBudgetTemplateItem[],
  ): Partial<IBudgetTemplateItem>[] {
    const sheetData = [];
    for (const item of templateItems) {
      if (isManagerTemplateItem(item)) {
        sheetData.push({
          order: item.order,
          description: item.description,
          account_code: item.account_code,
        });
      } else {
        sheetData.push({
          order: item.order,
          description: item.description,
          division: item.division,
          cost_type: item.cost_type,
        });
        sheetData.push(...this.convertSubItemsToSheetData(item));
      }
    }
    return sheetData;
  }

  private convertSubItemsToSheetData(item: IBudgetTemplateItemGC) {
    if (!item.subitems?.length) {
      return [];
    }
    return item.subitems.map((subitem) => {
      return {
        order: `${item.order}.${subitem.order}`,
        description: subitem.description,
        division: subitem.division,
        cost_type: subitem.cost_type,
      };
    });
  }
}
