import { inject, Injectable } from '@angular/core';
import { RestRequestService } from '../restApi/rest-request.service';
import {
  BUDGET_TEMPLATES_GC,
  BUDGET_TEMPLATES_MANAGER,
  REST_NOTIFICATIONS_SETTINGS,
  TAG_TEMPLATES,
  TAG_TEMPLATES_ITEM,
} from '../restApi/RestRoutes';
import { Observable } from 'rxjs';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItem,
} from '../store/templates/templates.types';
import * as uuid from 'uuid';
import { DeepCopyService } from './deep-copy.service';
import { CurrentUserService } from './current-user.service';
import { isGCTemplateItem, isManagerTemplateItem } from '../store/templates/templates.constants';

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  rest = inject(RestRequestService);
  userService = inject(CurrentUserService);

  getBudgetTemplates(): Observable<IBudgetTemplate[]> {
    if (this.userService.isGeneralContractor) {
      return this.rest.getWithObservable(BUDGET_TEMPLATES_GC);
    }
    return this.rest.getWithObservable(BUDGET_TEMPLATES_MANAGER);
  }

  createBudgetTemplate(template: Partial<IBudgetTemplate>): Observable<IBudgetTemplate> {
    if (this.userService.isGeneralContractor) {
      return this.rest.postWithObservable(BUDGET_TEMPLATES_GC, this.clearBudgetTemplate(template));
    }

    return this.rest.postWithObservable(
      BUDGET_TEMPLATES_MANAGER,
      this.clearBudgetTemplate(template),
    );
  }
  updateBudgetTemplate(template: IBudgetTemplate): Observable<IBudgetTemplate> {
    // / why is this thing sending invalid data to the backend?
    console.log(
      'update budget template request sent with',
      this.clearBudgetTemplate(template),
      'original template',
      template,
    );
    if (this.userService.isGeneralContractor) {
      return this.rest.patchWithObservable(
        `${BUDGET_TEMPLATES_GC}/${template.id}`,
        this.clearBudgetTemplate(template),
      );
    }

    return this.rest.patchWithObservable(
      `${BUDGET_TEMPLATES_MANAGER}/${template.id}`,
      this.clearBudgetTemplate(template),
    );
  }

  // todo: see later, maybe not needed..
  // removeBudgetTemplateItem(template: IBudgetTemplate): Observable<IBudgetTemplate> {
  //   const url = `${BUDGET_TEMPLATES_MANAGER}/${template.id}`;
  //   return this.rest.delWithObservable(url, this.clearBudgetTemplate(template));
  // }

  deleteBudgetTemplate(template: IBudgetTemplate): Observable<IBudgetTemplate> {
    if (this.userService.isGeneralContractor) {
      return this.rest.delWithObservable(`${BUDGET_TEMPLATES_GC}/${template.id}`);
    }
    return this.rest.delWithObservable(`${BUDGET_TEMPLATES_MANAGER}/${template.id}`);
  }

  removeTagTemplateItem(item: Partial<IBudgetTemplateItem>): Observable<IBudgetTemplateItem> {
    return this.rest.delWithObservable(`${TAG_TEMPLATES_ITEM}/${item.id}`);
  }
  removeTagTemplate(item: Partial<IBudgetTemplate>): Observable<IBudgetTagTemplate> {
    return this.rest.delWithObservable(`${TAG_TEMPLATES}/${item.id}`);
  }

  createBudgetTagTemplate(template: Partial<IBudgetTagTemplate>): Observable<IBudgetTagTemplate> {
    console.log('create tag template', this.clearTagsTemplate(template));
    return this.rest.postWithObservable(TAG_TEMPLATES, this.clearTagsTemplate(template));
  }
  updateBudgetTagTemplate(template: IBudgetTagTemplate): Observable<IBudgetTagTemplate> {
    const url = `${TAG_TEMPLATES}/${template.id}`;
    return this.rest.patchWithObservable(url, this.clearTagsTemplate(template));
  }

  getBudgetTagTemplates(projectId?: number): Observable<IBudgetTagTemplate[]> {
    if (!projectId) {
      return this.rest.getWithObservable(TAG_TEMPLATES);
    }

    return this.rest.getWithObservable(TAG_TEMPLATES, {}, { project_id: projectId });
  }

  getNotificationSettings(): Observable<any> {
    return this.rest.getWithObservable(REST_NOTIFICATIONS_SETTINGS);
  }

  updateNotificationSettings(settings: any): Observable<any> {
    return this.rest.putWithObservable(REST_NOTIFICATIONS_SETTINGS, settings);
  }

  /**
   * Remove permissions and uuids from budget tag templates before sending to backend
   * @param template
   */
  clearTagsTemplate(template: Partial<IBudgetTagTemplate>): Partial<IBudgetTagTemplate> {
    if (!template) {
      return template;
    }
    template = DeepCopyService.deepCopy(template);
    delete template?.permissions;
    delete template?.is_edit;
    delete template?.is_pending;
    delete template?.has_error;

    template.tags = template.tags.filter((tag) => tag.name.replace(/\s/g, '').length);

    template.tags.forEach((tag) => {
      delete tag?.permissions;
      // delete locally added uuids as they are not valid ids for backend
      if (uuid.validate(tag.id)) {
        delete tag?.id;
      }
    });

    return template;
  }

  /**
   * Remove permissions and uuids from budget templates before sending to backend
   * @param template
   */
  clearBudgetTemplate(template: Partial<IBudgetTemplate>) {
    if (!template) {
      return template;
    }

    template = DeepCopyService.deepCopy(template);
    delete template?.permissions;
    delete template?.is_edit;
    delete template?.is_pending;
    delete template?.has_error;

    console.log('before filtering', template.template_items);

    // filter out all-empty items
    template.template_items = template.template_items.filter((it) => {
      if (isManagerTemplateItem(it)) {
        return (
          it?.description?.replace(/\s/g, '')?.length !== 0 ||
          it.account_code.replace(/\s/g, '').length !== 0
        );
      }
      return (
        it?.description?.replace(/\s/g, '')?.length !== 0 ||
        it.cost_type.replace(/\s/g, '').length !== 0 ||
        it.division.replace(/\s/g, '').length !== 0
      );
    });

    console.log('after filtering', template.template_items);
    template.template_items.forEach((item) => {
      delete item?.permissions;
      // delete locally added uuids as they are not valid ids for backend
      if (uuid.validate(item.id)) {
        delete item?.id;
      }

      if (isGCTemplateItem(item)) {
        item.subitems.forEach((subitem) => {
          delete subitem?.permissions;
          // delete locally added uuids as they are not valid ids for backend
          if (uuid.validate(subitem.id)) {
            delete subitem?.id;
          }
        });
      }
    });
    return template;
  }
}
