import { SafeUrl } from '@angular/platform-browser';
import { IPermissions } from '../documents/documents.reducer';
import { User } from '../../framework/constants/user.constants';

export interface IProjectProperty {
  id: number;
  name: string;
  projects: ITeamProject[];
  team_id: number;
  checked?: boolean;
  address: string;
}

export interface ITeamProject {
  id: number;
  title: string;
  property_id: number;
  bids?: number;
  bids_total?: number;
  user?: Partial<User>;
}

export interface ITeamProperty {
  id: number;
  name: string;
  formatted_address: string;
}

// todo: decide if it should be kept
// export interface ITeamProject {
//   id: number;
//   property_id: number;
//   title: string;
// }
//
// export interface IPropertyWithProjects {
//   id: number;
//   name: string;
//   address: string;
//   team_id: number;
//   projects: ITeamProject[];
// }

export interface ITeamMember {
  is_invitation_pending: boolean;
  profile_picture_id: any;
  id: number;
  first_name: string;
  last_name: string;
  role: ROLE_TYPE;
  email: string;
  home_base: {
    id: number;
    name: string;
  };
  home_base_id: number;
  company_name: string;
  position: string;
  permissions: IPermissions;
  template_limit: boolean;
  properties_count?: number;
  projects_count?: number;
  team_id?: number;
  limit_to_templates?: boolean;
  share_future_projects?: boolean;
  user_id: number;
  team_members: ITeamMember[];
  token: string;
}

export interface ITeamMemberExtended extends ITeamMember {
  projects: ITeamProject[];
  properties: ITeamProperty[];
}

export interface ITeamMemberPermissions {
  can_delete: boolean;
  can_edit: boolean;
}

export interface ITeamProfilePicture {
  [key: number]: SafeUrl;
}

export enum ROLE_TYPE {
  MANAGER = 'manager',
  OWNER = 'owner',
  SPECTATOR = 'spectator',
  ADMIN = 'admin',
}

export enum TEAM_MANAGEMENT_SIDEBAR_VIEW {
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
}

export enum TEAM_MANAGEMENT_SIDEBAR_PAGES {
  FIRST_PAGE,
  SECOND_PAGE,
  LAST_PAGE,
}

export enum TEAM_PROP_PROJ_SIDEBAR_PAGES {
  PROPERTIES,
  PROJECTS,
}

export interface TeamMemberSimple {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
  position: string;
  email: string;
  profile_picture_url: string;
  home_base?: {
    id: number;
    name: string;
  };
}
