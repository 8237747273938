import { createFeatureSelector, createSelector } from '@ngrx/store';
import { commitmentsFeatureKey } from './commitments.reducer';
import {
  COMMITMENTS_INTERACTION_BAR_TYPE,
  COMMITMENTS_TYPE,
} from '../../framework/constants/interaction-bar.constants';
import { templateSelectors } from '../templates/templates.selectors';
import { IBudgetTagTemplate } from '../templates/templates.types';
import {
  COMMITMENT_APPROVAL_STATUS,
  CommitmentItemBackend,
  CommitmentItemBackendExtended,
  CommitmentsState,
  CostBackendExtended,
  CostBackendInvoiceExtended,
  ISidebarCommitment,
  ISidebarCommitmentItem,
} from './commitments.types';
import cloneDeep from 'lodash/cloneDeep';

const getCommitmentsState = createFeatureSelector<CommitmentsState>(commitmentsFeatureKey);

const getCommitmentsSummary = createSelector(
  getCommitmentsState,
  (state) => state.allCommitmentsSummary,
);
const getCommitments = createSelector(getCommitmentsState, (state) => state.allCommitments);

const allContractors = createSelector(getCommitments, (state) => {
  return {
    contractors: state?.contractors ?? [],
    temporary_contractors: state?.temporary_contractors ?? [],
  };
});

const allContractorsArray = createSelector(getCommitments, (state) => {
  return [...(state?.contractors ?? []), ...(state?.temporary_contractors ?? [])];
});

const currentSidebarPage = createSelector(getCommitmentsState, (state) => state.sidebarPage);

const getDirectCostsSummary = createSelector(
  getCommitmentsState,
  (state) => state.directCostsSummary ?? { direct_costs: [] },
);

const getSelectedCommitment = createSelector(
  getCommitmentsState,
  (state) => state.selectedCommitment,
);

const getSelectedContract = createSelector(getCommitmentsState, (state) => {
  const selectedContract = cloneDeep(state.selectedContract);
  if (!selectedContract) {
    return null;
  }
  selectedContract.change_orders.forEach((changeOrder) => {
    changeOrder.totalDuration = changeOrder.commitment_items.reduce(
      (acc, cur) => acc + cur.duration,
      0,
    );
  });

  return selectedContract;
});

const getContractSpendEntries = createSelector(getCommitmentsState, (state) => {
  return state?.sideBarContract?.commitment_items
    .map((item) => {
      const filteredCosts = item.costs
        .filter((cost) => !cost?.deleted)
        .map((cost) => {
          return {
            ...cost,
            is_disabled:
              (state.sideBarContract.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED &&
                !!state?.sideBarContract?.id) ||
              !!cost?.is_disabled,
          };
        });
      return { ...item, costs: filteredCosts };
    })
    .filter((item) => !item?.deleted);
});

const getChangeOrderEntries = createSelector(getCommitmentsState, (state) => {
  const contract = state?.sidebarSelectedContractSummary?.contract;
  return state?.sideBarChangeOrder?.commitment_items
    .map((commitmentItem) => {
      const filteredCosts = commitmentItem.costs
        .filter((cost) => !cost?.deleted)
        .map((cost) => {
          return {
            ...cost,
            is_disabled:
              (state.sideBarChangeOrder.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED &&
                !!state?.sideBarChangeOrder?.id) ||
              !!cost?.is_disabled,
          };
        });
      if (!contract) {
        return { ...commitmentItem, costs: filteredCosts };
      }

      const foundItem = contract.commitment_items.find(
        (ci) => ci.item?.id === commitmentItem.item?.id,
      );

      return {
        ...commitmentItem,
        item: {
          ...commitmentItem.item,
          contractApproved: !!foundItem,
        },
        costs: filteredCosts,
      };
    })
    .filter((item) => !item?.deleted);
});

const getDirectCostSpendEntries = createSelector(getCommitmentsState, (state) => {
  return state?.sideBarDirectCost?.commitment_items
    .map((item) => {
      const filteredCosts = item.costs.filter((cost) => !cost?.deleted);
      return { ...item, costs: filteredCosts };
    })
    .filter((item) => !item?.deleted);
});

const getSideBarContract = createSelector(getCommitmentsState, (state) => {
  return state?.sideBarContract;
});

const getSidebarInvoice = createSelector(getCommitmentsState, (state) => {
  return state?.sideBarInvoice;
});

const getSidebarDirectCost = createSelector(getCommitmentsState, (state) => {
  return state?.sideBarDirectCost;
});

const getSideBarChangeOrder = createSelector(getCommitmentsState, (state) => {
  return state?.sideBarChangeOrder;
});

const getSidebarCommitment = (commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE) =>
  createSelector(getCommitmentsState, (state): ISidebarCommitment => {
    switch (commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT: {
        return state?.sideBarContract;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER: {
        return state?.sideBarChangeOrder;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES: {
        return state?.sideBarInvoice;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST: {
        return state?.sideBarDirectCost;
      }
    }
    console.warn('not implemented');
    return null;
  });

const getBudgetLineItems = createSelector(getCommitmentsState, (state) => {
  return state.budgetLineItems;
});

const getInvoiceCosts = createSelector(getCommitmentsState, (state) =>
  state.sideBarInvoice.costs.filter((cost) => !cost?.deleted),
);

const reduceCost = (acc, cost) => {
  return (Number(acc) ?? 0) + Number(cost?.value) ?? 0;
};

const reduceEntry = (acc, entry) => {
  return acc + entry.costs.reduce(reduceCost, 0);
};

const totalContractCostDescription = createSelector(getContractSpendEntries, (entries) => {
  return entries.reduce(reduceEntry, 0);
});

const totalChangeOrderCostDescription = createSelector(getChangeOrderEntries, (entries) => {
  return entries.reduce(reduceEntry, 0);
});

const totalInvoiceCostDescription = createSelector(getInvoiceCosts, (costs) => {
  return costs.reduce(reduceCost, 0);
});

const totalDirectCostsDescriptionTotals = createSelector(getDirectCostSpendEntries, (entries) => {
  return entries.reduce(reduceEntry, 0);
});

const getProjectData = createSelector(getCommitmentsState, (state) => state.projectData);

const getAllContractsSummary = createSelector(
  getCommitmentsState,
  (state) => state.allCommitmentsSummary?.contracts ?? [],
);

const getAllApprovedContractsSummary = createSelector(
  getAllContractsSummary,
  (contracts) =>
    contracts.filter(
      (contract) => contract.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED,
    ) ?? [],
);

const getSidebarSelectedContractSummary = createSelector(getCommitmentsState, (state) => {
  return state.sidebarSelectedContractSummary;
});

/**
 * Role of this selector is to filter the costs by the budget tag id and line item id from the currently selected contract
 * @param index
 * @param lineId
 * @param budgetTagId
 */

const getSelectableLineItemsForChangeOrder = (index: number) =>
  createSelector(getCommitmentsState, (state) => {
    const changeOrderCommitmentItem: ISidebarCommitmentItem =
      state.sideBarChangeOrder.commitment_items[index];
    // console.log(index, state.sideBarChangeOrder, changeOrderCommitmentItem);
    const budgetTagId = changeOrderCommitmentItem?.budget_tag?.id;
    const lineId = changeOrderCommitmentItem?.item?.id;

    if (!lineId || !state.sidebarSelectedContractSummary) {
      return [];
    }

    const contract = state.sidebarSelectedContractSummary.contract;

    const commitmentItems = contract.commitment_items.filter((commitmentItem) => {
      if (budgetTagId) {
        return (
          commitmentItem?.item?.id === lineId && commitmentItem?.budget_tag?.id === budgetTagId
        );
      }
      return commitmentItem?.item?.id === lineId && !commitmentItem?.budget_tag?.id;
    });

    const extendedCommitmentItems: CommitmentItemBackendExtended[] = commitmentItems.map(
      (commitmentItem) => {
        const costs: CostBackendExtended[] = commitmentItem.costs
          .map((contractCost) => {
            const is_disabled = !!changeOrderCommitmentItem.costs.find(
              (cost) =>
                (cost.parent_id && cost.parent_id === contractCost.id) ||
                cost.id === contractCost.id,
            );
            return {
              ...contractCost,
              is_disabled,
            };
          })
          .filter(
            (contractCost) =>
              !changeOrderCommitmentItem.costs.find(
                (changeOrderCost) => changeOrderCost.id === contractCost.id,
              ),
          );

        return {
          ...commitmentItem,
          costs,
        };
      },
    );

    return extendedCommitmentItems;
  });

const getSelectableInvoiceCosts = createSelector(getCommitmentsState, (state) => {
  const budgetTagId = state.sideBarInvoice?.budget_tag?.id;

  if (!state.sidebarSelectedContractSummary) {
    return [];
  }

  const contract = state.sidebarSelectedContractSummary.contract;

  const commitmentItems: CommitmentItemBackend[] = contract.commitment_items.filter(
    (commitmentItem) => {
      if (budgetTagId) {
        return commitmentItem?.budget_tag?.id === budgetTagId;
      }
      return true;
    },
  );

  return commitmentItems.flatMap((item) => {
    return item.costs.flatMap((cost) => {
      const isDisabled = !!state.sideBarInvoice.costs.find(
        (selectedCost) => selectedCost.parent_id && selectedCost.parent_id === cost.id,
      );
      return {
        ...cost,
        value: cost.value,
        is_disabled: isDisabled,
        item: item.item,
        budget_tag: item.budget_tag,
      } as CostBackendInvoiceExtended;
    });
  });
});

const isSidebarLoading = createSelector(getCommitmentsState, (state) => state.isSidebarLoading);
const isSelectedCommitmentsSummaryLoading = createSelector(
  getCommitmentsState,
  (state) => state.isSelectedCommitmentViewSummaryLoading,
);

const getSidebarChanged = createSelector(getCommitmentsState, (state) => state.sideBarChanged);

const getHasCommitmentDependency = (commitmentType: COMMITMENTS_INTERACTION_BAR_TYPE) =>
  createSelector(getCommitmentsState, (state) => {
    switch (commitmentType) {
      case COMMITMENTS_INTERACTION_BAR_TYPE.ADD_CONTRACT: {
        return !!state?.sideBarContract?.has_commitments;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.CHANGE_ORDER: {
        return !!state?.sideBarChangeOrder?.has_commitments;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.INVOICES: {
        return !!state?.sideBarInvoice?.has_commitments;
      }
      case COMMITMENTS_INTERACTION_BAR_TYPE.DIRECT_COST: {
        return !!state?.sideBarDirectCost?.has_commitments;
      }
      default: {
        return false;
      }
    }
  });

const canAddMoreInvoiceCost = createSelector(getCommitmentsState, (state) => {
  const totalSelectableCosts =
    state.sidebarSelectedContractSummary.contract.commitment_items.reduce(
      (acc, item) => (acc += item.costs.length),
      0,
    );
  return state.sideBarInvoice.costs.length < totalSelectableCosts;
});

const reduceTemplateList = (acc: number, template: IBudgetTagTemplate) => {
  return acc + template.tags.length ?? 0;
};

const canAddContractSpendEntries = createSelector(
  getSideBarContract,
  getBudgetLineItems,
  getContractSpendEntries,
  templateSelectors.getBudgetTagTemplates,
  (sideBarContract, lineItems, entries: ISidebarCommitmentItem[], budgeTemplates) => {
    const tags = budgeTemplates.reduce(reduceTemplateList, 0);
    // note: can add if entries are less than line items * (tags + 1) combo, + 1 is empty tag
    // AND contract is not being edited(if has id then it's edit mode) with approved status
    return (
      entries.length < lineItems.length * (tags + 1) &&
      !(
        sideBarContract.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED &&
        !!sideBarContract.id
      )
    );
  },
);

const canAddChangeOrderSpendEntries = createSelector(
  getSideBarChangeOrder,
  getBudgetLineItems,
  getChangeOrderEntries,
  templateSelectors.getBudgetTagTemplates,
  (sidebarChangeOrder, lineItems, entries: ISidebarCommitmentItem[], budgeTemplates) => {
    const tags = budgeTemplates.reduce(reduceTemplateList, 0);
    // note: can add if entries are less than line items * (tags + 1) combo, + 1 is empty tag
    // AND CO is not being edited(if has id then it's edit mode) with approved status
    return (
      entries.length < lineItems.length * (tags + 1) &&
      !(
        sidebarChangeOrder.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED &&
        !!sidebarChangeOrder.id
      )
    );
  },
);

const canAddDirectCostSpendEntries = createSelector(
  getBudgetLineItems,
  getDirectCostSpendEntries,
  templateSelectors.getBudgetTagTemplates,
  (lineItems, entries: ISidebarCommitmentItem[], budgeTemplates) => {
    const tags = budgeTemplates.reduce(reduceTemplateList, 0);
    return entries.length < lineItems.length * (tags + 1);
  },
);

const canAddInvoiceCosts = createSelector(
  getSidebarInvoice,
  getSidebarSelectedContractSummary,
  (invoice, contractSummary) => {
    return (
      invoice?.costs?.length <
      contractSummary?.contract?.commitment_items?.reduce(
        (acc, item) => (acc += item?.costs?.length ?? 0),
        0,
      )
    );
  },
);

const hasSomeContract = createSelector(allContractors, (allContractors) => {
  return (
    allContractors.contractors.some((contractor) =>
      contractor.commitments.some(
        (commitment) =>
          commitment.type === COMMITMENTS_TYPE.CONTRACTS &&
          commitment.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED,
      ),
    ) ||
    allContractors.temporary_contractors.some((contractor) =>
      contractor.commitments.some(
        (commitment) =>
          commitment.type === COMMITMENTS_TYPE.CONTRACTS &&
          commitment.approval_status === COMMITMENT_APPROVAL_STATUS.APPROVED,
      ),
    )
  );
});

export const commitmentsSelectors = {
  getCommitmentsState,
  getCommitmentsSummary,
  getCommitments,
  getDirectCostsSummary,
  getSelectedCommitment,
  getSelectedContract,
  allContractors,
  allContractorsArray,
  currentSidebarPage,
  getContractSpendEntries,
  getSideBarContract,
  getBudgetLineItems,
  totalContractCostDescription,
  totalChangeOrderCostDescription,
  getProjectData,
  getAllContractsSummary,
  getAllApprovedContractsSummary,
  getSideBarChangeOrder,
  getChangeOrderEntries,
  getSelectableLineItemsForChangeOrder,
  getSidebarSelectedContractSummary,
  getSidebarInvoice,
  totalInvoiceCostDescription,
  totalDirectCostsDescriptionTotals,
  getInvoiceCosts,
  getSidebarCommitment,
  isSidebarLoading,
  isSelectedCommitmentsSummaryLoading,
  getSidebarDirectCost,
  getDirectCostSpendEntries,
  getSelectableInvoiceCosts,
  getSidebarChanged,
  getHasCommitmentDependency,
  canAddMoreInvoiceCost,
  canAddInvoiceCosts,
  hasSomeContract,
};

export const commitmentCombinedSelectors = {
  canAddContractSpendEntries,
  canAddChangeOrderSpendEntries,
  canAddDirectCostSpendEntries,
};
