<div class="templates-wrapper page-wrapper">
  <app-general-header title="Templates">
    <div class="header-inputs">
      @if (userService.isManager) {
        <app-tab-selector-button
          (click)="selectTemplateView(TEMPLATE_VIEWS.BUDGET)"
          [active]="templateView === TEMPLATE_VIEWS.BUDGET"
          >Project Budget
        </app-tab-selector-button>
        <app-tab-selector-button
          (click)="selectTemplateView(TEMPLATE_VIEWS.BUDGET_TAG)"
          [active]="templateView === TEMPLATE_VIEWS.BUDGET_TAG"
          >Budget Tags
        </app-tab-selector-button>
      }
    </div>
  </app-general-header>
  <!--  <ng-container *ngIf="currentTab === TEMPLATE_TABS.ACCOUNT_TEMPLATE">-->
  <!--    <app-glaccounts></app-glaccounts>-->
  <!--  </ng-container>-->

  <main class="page-content">
    <ng-container *ngIf="templateView === TEMPLATE_VIEWS.BUDGET">
      <app-project-budget-templates></app-project-budget-templates>
    </ng-container>
    <ng-container *ngIf="templateView === TEMPLATE_VIEWS.BUDGET_TAG">
      <app-budget-tag-templates></app-budget-tag-templates>
    </ng-container>
  </main>

  <footer class="flex gap-2 items-center">
    <div class="text-shade_1 text-size_xl font-semibold">
      {{ autoSaveStatus$ | async }}
    </div>
    <div class="h-[32px] w-[56px] flex items-center">
      @switch (autoSaveStatus$ | async) {
        @case (TEMPLATES_AUTO_SAVE_STATES.LOADING) {
          <div class="dots-container">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
        }
        @case (TEMPLATES_AUTO_SAVE_STATES.SAVED_SUCCESSFULLY) {
          <span class="icon-approved text-[22px] text-color_accept"></span>
        }
        @case (TEMPLATES_AUTO_SAVE_STATES.PENDING) {
          <span class="icon-warning text-color_accent_shade_1 text-[22px]"></span>
        }
        @case (TEMPLATES_AUTO_SAVE_STATES.ERROR) {
          <span class="icon-warning text-[22px] text-color_reject"></span>
        }
      }
    </div>
    <!--    <app-simple-button buttonStyle="color_accent_filled" size="large" (click)="saveTemplates()">Save</app-simple-button>-->
  </footer>
</div>
