export enum SPEND_TYPES {
  BUDGET = 'Budget',
  BUDGET_COMMITMENTS = 'Budget & Commitments',
  COMMITMENTS = 'Commitments',
}

export enum SPEND_ACTIONS {
  REFRESH,
  OPEN,
  CLOSE,
  SAVE,
  UPDATE,
  CLOSE_SHEETS,
  CHANGE_BUDGET_TYPE,
  // LOCK_BUDGET, // not used anymore
}

// this is used in spend service
export const MONTH_IS_BEFORE_YEAR = -1;
export const MONTH_IS_AFTER_YEAR = 13;
