<ng-scrollbar #scrollbar class="std-scrollbar flex-1" [appearance]="'compact'" orientation="vertical">
  <div class="flex my-2.5 mx-7 flex-col flex-1">
    <form class="flex flex-col h-full" #generalPage="ngForm" role="form">
      <app-upload-window
        class="flex items-center"
        [allowMultipleFiles]="true"
        [hideUploadIcon]="true"
        (chosenFiles)="registerFileUpload($event)"
        #uploadWindowComponent
      >
        <!--            // todo: create a component for this-->
        <div class="border border-shade_5 rounded border-solid p-3 mt-0.5 w-full">
          <span
            (click)="uploadWindowComponent.hiddenFileInput.openFileUploadWindow()"
            class="text-color_secondary flex items-center gap-1.5 pointer-cursor"
          >
            <span class="text-xl icon-folded-file-upload flex items-center"></span>
            <span class="italic text-color_secondary font-bold text-size_l"> Click to upload files</span>
          </span>
          @if (model.files.length) {
            @for (file of model.files; track file.id; let index = $index) {
              <span class="flex items-center gap-5 pointer-cursor">
                <span class="text-color_secondary my-1.5 flex-1">
                  {{ file.name | shortenFileName }}
                </span>
                <span
                  (click)="removeFile(file, index)"
                  class="icon-remove-circle-x text-size_icon text-color_secondary hover:text-color_accent"
                ></span>
              </span>
            }
          }
        </div>
      </app-upload-window>

      <app-floating-input
        required
        [(model)]="model.name"
        name="contract_title"
        label="Contract Title"
        placeholder="Contract Title"
      ></app-floating-input>

      <app-floating-input
        required
        [(model)]="model.owner_name"
        name="owner_name"
        label="Owner Name"
        placeholder="Owner Name"
      ></app-floating-input>

      <div class="flex gap-4">
        <app-input-calendar
          required
          [(date)]="model.date"
          class="w-full"
          name="contract_date"
          placeholder="Contract Date"
        ></app-input-calendar>
        <app-dropdown
          required
          [optionLabel]="null"
          [optionValue]="null"
          [required]="true"
          placeholder="Contract Status"
          label="Contract Status"
          id="status"
          name="status"
          [(model)]="model.status"
          [options]="statuses"
          class="w-full"
        >
          <ng-template #selectedContent #optionContent let-option>
            <div class="capitalize text-color_primary text-medium">{{ option }}</div>
          </ng-template>
        </app-dropdown>
      </div>

      <label class="flex gap-1 text-shade_2 text-xs mt-2 ml-1 mb-0.5">
        <span class="font-semibold">Contract Description</span>
        <span class="font-normal">(optional)</span>
      </label>
      <div (click)="textAreaElement.focus()" class="textarea-wrapper cursor-text">
        <textarea class="flex" #textAreaElement cdkTextareaAutosize name="note" [(ngModel)]="model.note"></textarea>
      </div>
    </form>
  </div>
</ng-scrollbar>
<div class="my-6 mx-7 flex justify-end gap-4">
  <app-simple-button (click)="goToNextPage()">Next</app-simple-button>
</div>
