import { createAction, props } from '@ngrx/store';
import { Project, ProjectDescription } from '../../pages/webapp/projects/projects.interface';
import { ProjectSpend } from '../../services/project-spend.service';
import { ILineItem } from '../spend/spend.interfaces';
import {
  PROJECT_VIEWS,
  VIEW_PROJECT_REFRESH_CAUSE,
} from '../../framework/constants/view-project.constants';
import { ProjectDescriptionPayload } from '../../pages/webapp/projects/view-project/project-updates/project-updates.types';
import { IBudgetTemplate } from '../templates/templates.types';

const cancel = createAction('[View Project] Cancel');

const selectedProjectChanged = createAction(
  '[View Project] Selected Project Changed',
  props<{ projectId: number }>(),
);

const projectLoaded = createAction('[View Project] Project Loaded', props<{ project: Project }>());

const startedLoading = createAction(
  '[View Project] Started Loading',
  props<{
    target?: 'spends' | 'lineItems' | 'general' | 'descriptions' | 'reload-descriptions' | null;
  }>(),
);
const loadingEnded = createAction(
  '[View Project] Loading Ended',
  props<{
    target?: 'spends' | 'lineItems' | 'general' | 'descriptions' | 'reload-descriptions' | null;
  }>(),
);

const refreshNeeded = createAction(
  '[View Project] Refresh',
  props<{
    // describes why is the refresh needed - different things should be loaded based on the cause
    cause?: VIEW_PROJECT_REFRESH_CAUSE;
  }>(),
);

const loadSpends = createAction('[View Project] Load Spends', props<{ projectId?: number }>());
const spendsLoaded = createAction(
  '[View Project] Spends Loaded',
  props<{ projectId: number; spends: ProjectSpend[] }>(),
);

const loadProjectDescriptions = createAction(
  '[View Project] Load Project Descriptions',
  props<{ projectId?: number }>(),
);

const reloadProjectDescriptions = createAction(
  '[View Project] Reload Project Descriptions',
  props<{ projectId?: number }>(),
);

const successfullySaved = createAction('[View Project] Successfully saved Project Description');

const deleteProjectDescriptions = createAction(
  '[View Project] Delete Project Descriptions',
  props<{ descriptionId: number }>(),
);

const updateProjectDescriptions = createAction(
  '[View Project] Update Project Descriptions',
  props<{ projectDescription: Partial<ProjectDescriptionPayload> }>(),
);

const createProjectDescriptions = createAction(
  '[View Project] Create Project Descriptions',
  props<{ projectDescription: ProjectDescriptionPayload }>(),
);

const saveProjectDescriptionsAsync = createAction(
  '[View Project] Save, Update & Delete Async Project Descriptions',
  props<{
    newDescriptions: ProjectDescriptionPayload[];
    updatedDescriptions: ProjectDescriptionPayload[];
    deletedIds: number[];
  }>(),
);
const deleteProjectDescriptionFromStore = createAction(
  '[View Project] Delete Project Description from store by id',
  props<{ id: number }>(),
);

const projectDescriptionsLoaded = createAction(
  '[View Project]  Project Descriptions Loaded',
  props<{ projectId: number; projectDescriptions: ProjectDescription[] }>(),
);

const loadLineItems = createAction(
  '[View Project] Load Line Items',
  props<{ projectId?: number }>(),
);
const lineItemsLoaded = createAction(
  '[View Project] Line Items Loaded',
  props<{ projectId: number; lineItems: ILineItem[] }>(),
);

const loadTemplatesByProperty = createAction(
  '[View Project] Load Templates By Property',
  props<{ propertyId: number }>(),
);

const projectTemplatesLoaded = createAction(
  '[View Project] Project Templates Loaded',
  props<{ templates: IBudgetTemplate[] }>(),
);

const viewChanged = createAction('[View Project] View Changed', props<{ view: PROJECT_VIEWS }>());
const changeView = createAction('[View Project] Change View', props<{ view: PROJECT_VIEWS }>());

const onDestroy = createAction('[View Project] On Destroy');

export const viewProjectActions = {
  cancel,
  selectedProjectChanged,
  projectLoaded,
  startedLoading,
  loadingEnded,
  refreshNeeded,
  loadSpends,
  spendsLoaded,
  loadLineItems,
  lineItemsLoaded,
  viewChanged,
  changeView,
  onDestroy,
  loadTemplatesByProperty,
  projectTemplatesLoaded,
  loadProjectDescriptions,
  reloadProjectDescriptions,
  projectDescriptionsLoaded,
  deleteProjectDescriptions,
  updateProjectDescriptions,
  createProjectDescriptions,
  deleteProjectDescriptionFromStore,
  saveProjectDescriptionsAsync,
  successfullySaved,
};
