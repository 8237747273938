import { createFeatureSelector, createSelector } from '@ngrx/store';
import { templatesFeatureKey } from './templates.reducer';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  IBudgetTemplateItemGC,
  ITemplatesState,
  TEMPLATES_AUTO_SAVE_STATES,
  TemplateViews,
} from './templates.types';

const sortByOwnerShip = (
  a: IBudgetTemplate | IBudgetTagTemplate,
  b: IBudgetTemplate | IBudgetTagTemplate,
) => {
  if (a.is_owner && !b.is_owner) {
    return -1;
  }
  if (!a.is_owner && b.is_owner) {
    return 1;
  }
  return 0;
};

const templatesFeatureSelector = createFeatureSelector<ITemplatesState>(templatesFeatureKey);

const getBudgetTemplates = createSelector(templatesFeatureSelector, (state) =>
  state.budgetTemplates.filter((template) => !template?.is_deleted).toSorted(sortByOwnerShip),
);

const getBudgetTemplateItemsNonEmpty = createSelector(templatesFeatureSelector, (state) =>
  state.budgetTemplates.filter(
    (template) => template.template_items.length && !template?.is_deleted,
  ),
);

const getSelectedBudgetTemplate = createSelector(templatesFeatureSelector, (state) => {
  const selectedTemplate = state.budgetTemplates.find(
    (template) => template.id === state.selectedBudgetTemplateId,
  );
  return {
    ...selectedTemplate,
    template_items: selectedTemplate?.template_items
      ?.filter((item) => !item?.is_deleted)
      .map((item) => {
        return {
          ...item,
          subitems: (item as IBudgetTemplateItemGC).subitems?.filter(
            (subitem) => !subitem?.is_deleted,
          ),
        };
      }),
  };
});

const getIsLoading = createSelector(templatesFeatureSelector, (state) => state.isLoading);

const getSelectedBudgetTagTemplate = createSelector(templatesFeatureSelector, (state) => {
  const selectedTagTemplate = state.budgetTagTemplates.find(
    (template) => template.id === state.selectedBudgetTagTemplateId,
  );
  return {
    ...selectedTagTemplate,
    tags: selectedTagTemplate?.tags?.filter((tag) => !tag?.is_deleted),
  };
});

const getBudgetTagTemplates = createSelector(templatesFeatureSelector, (state) =>
  state.budgetTagTemplates.filter((template) => !template?.is_deleted).toSorted(sortByOwnerShip),
);

const getTemplate = (id: number, view: TemplateViews) =>
  createSelector(
    templatesFeatureSelector,
    (state: ITemplatesState): IBudgetTemplate | IBudgetTagTemplate => {
      let template: IBudgetTemplate | IBudgetTagTemplate;
      if (view === 'budget') {
        template = state.budgetTemplates.find((template) => template.id === id);
      } else if (view === 'budget-tag') {
        template = state.budgetTagTemplates.find((template) => template.id === id);
      }

      if (!template) {
        return null;
      }

      let has_permissions = true;
      if (template.permissions) {
        has_permissions = Object.values(template.permissions).some((permission) => !!permission);
      }

      template = {
        ...template,
        has_permissions,
      };
      return template;
    },
  );

const getAutoSaveStatus = (templateView: TemplateViews) =>
  createSelector(templatesFeatureSelector, (state) => {
    const baseEntity = templateView === 'budget' ? state.budgetTemplates : state.budgetTagTemplates;
    const autoSaveState =
      templateView === 'budget'
        ? state.autoSaveState.budgetTemplates
        : state.autoSaveState.tagTemplates;
    const someIsPending = baseEntity.some((template) => template.is_pending);
    const someHasError = baseEntity.some((template) => template.has_error);

    // order is important, we always want to show loading state if it's loading
    if (autoSaveState === TEMPLATES_AUTO_SAVE_STATES.LOADING) {
      return TEMPLATES_AUTO_SAVE_STATES.LOADING;
    }

    // after that the error/pending state is the most important
    if (someHasError) {
      return TEMPLATES_AUTO_SAVE_STATES.ERROR;
    }

    if (someIsPending) {
      return TEMPLATES_AUTO_SAVE_STATES.PENDING;
    }

    // if there is nothing suspicious, we return the general auto save state
    return autoSaveState;
  });

export const templateSelectors = {
  templatesFeatureSelector,
  getBudgetTemplates,
  getBudgetTemplateItemsNonEmpty,
  getSelectedBudgetTemplate,
  getIsLoading,
  getSelectedBudgetTagTemplate,
  getBudgetTagTemplates,
  getTemplate,
  getAutoSaveStatus,
};
