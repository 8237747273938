import { CurrentUserService } from '../services/current-user.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

export const redirectProjectListing = () => {
  const router = inject(Router);
  const currentUserService = inject(CurrentUserService);

  const redirectRoute = ['webapp', 'projects'];

  if (currentUserService.isManager) {
    redirectRoute.push('manager');
  }

  if (currentUserService.isGeneralContractor) {
    redirectRoute.push('general-contractor');
  }

  if (currentUserService.isServiceProvider) {
    redirectRoute.push('contractor');
  }

  router.navigate(redirectRoute);
};
