import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';
import { redirectProjectListing } from './redirect.helper';

export const canActivateProjectsContractorGuard: CanActivateFn = () => {
  const user = inject(CurrentUserService);
  const isServiceProvider = user.isServiceProvider;

  if (!isServiceProvider) {
    redirectProjectListing();
    return false;
  }
  return true;
};
