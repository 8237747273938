import { createFeatureSelector, createSelector } from '@ngrx/store';
import { viewProjectFeatureKey, ViewProjectState } from './view-project.reducer';
import { PROJECT_VIEWS } from '../../framework/constants/view-project.constants';

const getViewProjectState = createFeatureSelector<ViewProjectState>(viewProjectFeatureKey);

const selectProjectId = createSelector(getViewProjectState, (state) => state.currentProjectId);

const getSelectedView = createSelector(getViewProjectState, (state) => state.selectedView);

const getSelectedProject = createSelector(
  getViewProjectState,
  selectProjectId,
  (state, projectId) => state.projects.get(Number(projectId)),
);

const isLoading = createSelector(getViewProjectState, (state) => state.isLoading);
const isLoadingGeneral = createSelector(isLoading, (loading) => loading.general);
const isLoadingSpends = createSelector(isLoading, (loading) => loading.spends);
const isLoadingLineItems = createSelector(isLoading, (loading) => loading.lineItems);

const getSpends = createSelector(getViewProjectState, selectProjectId, (state, projectId) =>
  state.spends.get(projectId),
);

const getLineItems = createSelector(
  getViewProjectState,
  selectProjectId,
  (state, projectId) => state.lineItems.get(projectId) ?? [],
);

const hasTemplate = createSelector(getSelectedProject, (project) => !!project?.budget_template_id);
const hasBudget = createSelector(getSelectedProject, (project) => project?.items.length > 0);
const budgetTemplateAdjusted = createSelector(
  getSelectedProject,
  (project) => project?.budget_template_adjusted,
);

const selectAllLoadingStatesFinished = createSelector(
  isLoadingGeneral,
  isLoadingSpends,
  (isLoadingGeneral, isLoadingSpends) => {
    return !isLoadingGeneral && !isLoadingSpends;
  },
);

const allLoadingStatesFinished = createSelector(selectAllLoadingStatesFinished, (allFinished) => {
  return allFinished;
});

const isDescriptionsLoading = createSelector(
  getViewProjectState,
  (state) => state.isLoading.descriptions,
);

const areDescriptionsReloading = createSelector(
  getViewProjectState,
  (state) => state.isLoading.reloadDescriptions,
);

const projectDescriptions = createSelector(
  getViewProjectState,
  selectProjectId,
  (state, projectId) => state.descriptions.get(projectId),
);

const getProjectTemplates = (withTrashed: boolean) =>
  createSelector(getViewProjectState, (state) =>
    withTrashed
      ? state.projectTemplates
      : state.projectTemplates.filter((template) => !template.is_trashed),
  );

const selectedProjectBudgetMissing = createSelector(
  hasBudget,
  hasTemplate,
  getSelectedView,
  (budget, template, view) => {
    return (
      (view === PROJECT_VIEWS.CASHFLOW ||
        view === PROJECT_VIEWS.PROJECT_SPEND ||
        view === PROJECT_VIEWS.COMMITMENTS) &&
      !budget &&
      !template
    );
  },
);

const spendAllocationBudgetMissing = createSelector(
  hasBudget,
  hasTemplate,
  getSelectedView,
  (budget, template, view) => {
    return view === PROJECT_VIEWS.BUDGET_ALLOCATION && !budget && !template;
  },
);

export const viewProjectSelectors = {
  getViewProjectState,
  selectProjectId,
  getSelectedView,
  getSelectedProject,
  isLoadingGeneral,
  isLoadingSpends,
  isLoadingLineItems,
  getSpends,
  getLineItems,
  hasTemplate,
  hasBudget,
  budgetTemplateAdjusted,
  allLoadingStatesFinished,
  projectDescriptions,
  isDescriptionsLoading,
  areDescriptionsReloading,
  getProjectTemplates,
  selectedProjectBudgetMissing,
  spendAllocationBudgetMissing,
};
