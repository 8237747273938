import { inject, Injectable, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  PROJECT_STATUS_KEY,
  ProjectStatus,
} from '../../../pages/webapp/projects/projects.interface';
import {
  getProjectStatuses,
  isEditProjectLoading,
} from '../../../store/projects/projects.selectors';
import { Subject } from 'rxjs';
import { NotificationsService } from '../../../services/notifications.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app-state';
import { Actions, ofType } from '@ngrx/effects';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { delay, takeUntil, tap } from 'rxjs/operators';
import { projectActions } from '../../../store/projects/projects.actions';
import { ROUTE_WEBAPP } from '../../constants/route.webapp.constants';
import { viewProjectActions } from '../../../store/view-project/view-project.actions';

@Injectable()
export abstract class ProjectSetupBaseComponent implements OnDestroy, OnInit {
  protected readonly notif = inject(NotificationsService);
  protected readonly router = inject(Router);
  protected readonly store = inject(Store<AppState>);
  protected readonly actions = inject(Actions);
  protected readonly interactionBarService = inject(InteractionBarStateService);
  protected readonly userService = inject(CurrentUserService);
  protected readonly projectService = inject(ProjectApiService);

  abstract model: any;

  @ViewChild('setupForm') abstract setupForm: NgForm;
  @Input() isEdit: boolean;

  PROJECT_STATUS_KEY = PROJECT_STATUS_KEY;
  projectStatuses: ProjectStatus[] = [];
  projectStatuses$ = this.store.select(getProjectStatuses);

  isEditProjectLoading$ = this.store.select(isEditProjectLoading);
  isDestroyed$ = new Subject();

  customIdEditable = false;
  originalCustomId = null;
  hasCustomId = false;
  hasCommitments = false;

  /**
   * Updates project specific project ID using the backend.
   */
  abstract updateProjectId(...args: unknown[]): any;
  abstract startDateChanged(date: string): void;

  ngOnInit() {
    this.projectStatuses$.pipe(takeUntil(this.isDestroyed$)).subscribe((statuses) => {
      this.setAvailableProjectStatuses(statuses);
    });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.projectCreatedSuccessfully, projectActions.updateProjectSuccess),
        tap((action) => {
          const message =
            action.type === projectActions.projectCreatedSuccessfully.type ? 'created' : 'updated';
          this.notif.showSuccess(`Project successfully ${message}!`);
        }),
        delay(250),
      )
      .subscribe((action) => {
        if (action.type === projectActions.projectCreatedSuccessfully.type) {
          this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS, action.projectId], {});
        } else {
          this.store.dispatch(viewProjectActions.refreshNeeded({}));
        }
        this.interactionBarService.close();
      });

    this.actions
      .pipe(
        takeUntil(this.isDestroyed$),
        ofType(projectActions.successfulProjectDeletion),
        tap(() => {
          this.notif.showSuccess('Project successfully deleted!');
        }),
        delay(100),
        tap(() => {
          this.router.navigate([ROUTE_WEBAPP.BASE, ROUTE_WEBAPP.PROJECTS], {});
        }),
        delay(200),
      )
      .subscribe((_) => {
        this.interactionBarService.close();
      });
  }

  setAvailableProjectStatuses(statuses: ProjectStatus[]) {
    const hiddenStatuses: PROJECT_STATUS_KEY[] = [PROJECT_STATUS_KEY.DELETED];

    this.projectStatuses = statuses.filter((status) => {
      return !hiddenStatuses.includes(status.key as PROJECT_STATUS_KEY);
    });
  }

  delete() {
    this.notif.showPopup('Are you sure you want to delete this project?').then((answer) => {
      if (answer) {
        this.store.dispatch(projectActions.deleteProject({ id: this.model.id }));
      }
    });
  }

  onCustomIdIconClick() {
    if (this.customIdEditable) {
      // if it was editable, reset to original
      this.model.custom_project_id = this.originalCustomId;
    }
    this.customIdEditable = !this.customIdEditable;
  }

  customIdChanged(customId: string) {
    if (customId && typeof customId === 'string') {
      this.model.custom_project_id = customId.toUpperCase();
    }
  }

  /**
   * Validates and saves project data.
   */
  save() {
    this.setupForm.form.markAllAsTouched();

    const startDateControl = this.setupForm.form.controls.start_date;
    if (startDateControl?.hasError('matDatepickerMax')) {
      this.notif.showError(
        "Start date needs to be before or equal the line item's budget or forecast start date!",
      );
      return;
    }

    if (this.setupForm.invalid) {
      this.notif.showError('Please fill in all required fields.');
      return;
    }

    if (this.isEdit) {
      this.updateProject();
      return;
    }

    this.model.auto_generated_project_id = this.originalCustomId;
    this.store.dispatch(projectActions.createProject({ project: { ...this.model } }));
  }

  updateProject(): void {
    if (this.hasCommitments) {
      const { auto_generated_project_id, custom_project_id, ...restOfModel } = this.model;
      this.store.dispatch(projectActions.updateProject({ project: { ...restOfModel } }));
      return;
    }

    this.store.dispatch(projectActions.updateProject({ project: { ...this.model } }));
  }

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }
}
