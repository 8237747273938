<div class="contents">
  <div class="index-box ml-2">{{ templateItem.order }}.{{ templateSubitem.order }}</div>
  <span
    cdkDragHandle
    [ngClass]="{ 'disabled text-shade_4': !template?.permissions?.can_edit || templateItem.subitems.length < 2 }"
    class="icon-draggable text-color_secondary text-[20px] cursor-pointer ml-2"
  ></span>
  <app-floating-input
    class="ml-2"
    [classes]="{
      input: 'font-normal',
      wrapper: ''
    }"
    [name]="'division' + templateSubitem.id"
    [maxlength]="10"
    (modelChange)="fieldChanged('division', $event)"
    [model]="templateSubitem.division"
    [verticalSpacing]="false"
  ></app-floating-input>
  <app-floating-input
    [classes]="{
      input: 'font-normal',
      wrapper: ''
    }"
    [name]="'cost_type' + templateSubitem.id"
    [maxlength]="20"
    (modelChange)="fieldChanged('cost_type', $event)"
    [model]="templateSubitem.cost_type"
    [verticalSpacing]="false"
  ></app-floating-input>
  <app-floating-input
    [classes]="{
      input: 'font-normal',
      wrapper: ''
    }"
    [name]="'description' + templateSubitem.id"
    [maxlength]="40"
    (modelChange)="fieldChanged('description', $event)"
    [model]="templateSubitem.description"
    [verticalSpacing]="false"
  ></app-floating-input>
  <span (click)="minusClicked()" class="icon-minus-box text-color_red_light add-remove-btn"></span>
  <!--  <span (click)="plusClicked()" class="icon-plus-box text-color_secondary add-remove-btn"></span>-->
</div>
