<div class="contents">
  <div class="index-box">{{ templateItem.order }}</div>
  <span
    [ngClass]="{ 'disabled text-shade_4': !template?.permissions?.can_edit || template.template_items.length < 2 }"
    cdkDragHandle
    class="icon-draggable text-color_secondary text-[20px] cursor-pointer"
  ></span>
  @if (isManagerTemplate(templateItem)) {
    <app-floating-input
      [name]="'account_code' + templateItem.id"
      [maxlength]="20"
      (modelChange)="fieldChanged('account_code', $event)"
      [model]="templateItem.account_code"
      [verticalSpacing]="false"
      [disabled]="!template?.permissions?.can_edit"
    ></app-floating-input>
  } @else {
    <app-floating-input
      [classes]="{
        input: templateItem?.subitems?.length > 0 ? 'bg-shade_7' : '',
        wrapper: ''
      }"
      [name]="'division' + templateItem.id"
      [maxlength]="10"
      (modelChange)="fieldChanged('division', $event)"
      [model]="templateItem.division"
      [verticalSpacing]="false"
      [disabled]="!template?.permissions?.can_edit"
    ></app-floating-input>
    <app-floating-input
      [classes]="{
        input: templateItem?.subitems?.length > 0 ? 'bg-shade_7' : '',
        wrapper: ''
      }"
      [name]="'cost_type' + templateItem.id"
      [maxlength]="20"
      (modelChange)="fieldChanged('cost_type', $event)"
      [model]="templateItem.cost_type"
      [verticalSpacing]="false"
      [disabled]="!template?.permissions?.can_edit"
    ></app-floating-input>
  }
  <app-floating-input
    [classes]="{
      input: isGCTemplateItem(templateItem) && templateItem?.subitems?.length > 0 ? 'bg-shade_7' : '',
      wrapper: ''
    }"
    [name]="'description' + templateItem.id"
    [maxlength]="40"
    (modelChange)="fieldChanged('description', $event)"
    [model]="templateItem.description"
    [verticalSpacing]="false"
    [disabled]="!template?.permissions?.can_edit"
  ></app-floating-input>

  <span
    (click)="minusClicked()"
    [ngClass]="{
      'disabled hidden': !template.permissions.can_edit,
      'disabled grayscale': template.template_items.length < 2
    }"
    class="icon-minus-box text-color_red_light add-remove-btn"
  ></span>
  <span
    (click)="plusClicked()"
    [ngClass]="{ 'disabled hidden': !template.permissions.can_edit }"
    class="icon-plus-box text-color_secondary add-remove-btn"
  ></span>
</div>
