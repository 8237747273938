import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fillIn } from '../../../../assets/styles/animations';
import { ContractorDataSource, SORT_DIR } from '../../../dto/contractor.data.source';
import { OPTIONS } from '../../../framework/constants/options-list.constants';
import { ContractorsApiService } from '../../../services/contractors-api.service';
import { NotificationsService } from '../../../services/notifications.service';
import { ProjectApiService } from '../../../services/project-api.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { NotificationsHandler } from '../../../framework/interfaces/NotificationsHandler';
import { CONTRACTOR_SELECTOR } from '../../../framework/constants/view.constants';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import { NgScrollbar, NgScrollbarExt } from 'ngx-scrollbar';
import { take } from 'rxjs/operators';
import { DateCustomPipe, NO_TIMEZONE } from '../../../pipes/framework/date-custom.pipe';
import { PhonePipe } from '../../../pipes/framework/phone.pipe';
import { MatCheckbox } from '@angular/material/checkbox';
import { UnStyledOptionsListComponent } from '../../../framework/overlays/un-styled-options-list/un-styled-options-list.component';
import { ImgLoadingComponent } from '../../../framework/img-loading/img-loading.component';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { SearchBarMinimalistComponent } from '../../../framework/search-bar-minimalist/search-bar-minimalist.component';
import { NgScrollbarCdkVirtualScroll } from 'ngx-scrollbar/cdk';

@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.scss'],
  animations: [fillIn],
  standalone: true,
  imports: [
    SearchBarMinimalistComponent,
    NgClass,
    NgIf,
    MatTooltip,
    NgScrollbar,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    NgFor,
    ImgLoadingComponent,
    UnStyledOptionsListComponent,
    MatCheckbox,
    DateCustomPipe,
    PhonePipe,
    NgScrollbarExt,
    NgScrollbarCdkVirtualScroll,
  ],
})
export class ContractorsComponent
  extends NotificationsHandler
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild('scroll', { static: false }) scroll: NgScrollbar;
  @ViewChild('scroll2', { static: false }) scroll2: NgScrollbar;

  NO_TIMEZONE = NO_TIMEZONE;
  ICON_WIDTH = '40px';
  ICON_HEIGHT = '40px';

  isVieweByInvites = new EventEmitter();

  CONTRACTOR_SELECTOR = CONTRACTOR_SELECTOR;

  data: any = [];
  isEmptyData = true;

  state = CONTRACTOR_SELECTOR.ALL;
  contractors = [];

  contractosSelected = [];
  currentFilter = 0;

  /**
   * Backbone for table sort data
   */
  columnSort = {
    company: {
      sort: SORT_DIR.NONE,
    },
    addr: {
      sort: SORT_DIR.NONE,
    },
    contact: {
      sort: SORT_DIR.NONE,
    },
    license: {
      sort: SORT_DIR.NONE,
    },
    meeting: {
      sort: SORT_DIR.NONE,
    },
  };

  contractorOptions = [OPTIONS.MESSAGE, OPTIONS.CANCEL];

  constructor(
    private el: ElementRef,
    private contractorApi: ContractorsApiService,
    private projectApi: ProjectApiService,
    protected notif: NotificationsService,
    private barState: InteractionBarStateService,
    private interactionBarState: InteractionBarStateService,
  ) {
    super(notif);
    this.data = new ContractorDataSource(
      this.contractorApi,
      this.projectApi,
      this.notif,
      this.isVieweByInvites,
    );
  }

  ngOnInit() {
    this.openLoading();
    this.isNoData().then((data) => {
      this.isEmptyData = false;

      console.log('closing in');
      this.closeLoading();
    });
  }

  ngAfterViewInit(): void {
    this.updateScrollbar();
  }

  updateScrollbar() {
    this.virtualScroll.renderedRangeStream.pipe(take(3)).subscribe((ev) => {
      this.scroll.update();
      this.scroll2.update();
    });

    this.virtualScroll.scrolledIndexChange.pipe(take(3)).subscribe((ev) => {
      this.scroll.update();
      this.scroll2.update();
    });
  }

  track(ev) {}

  isNoData(): Promise<any> {
    return new Promise<any>((res) => {
      if (this.data.isDataEmpty()) {
        // console.log(this.data.isDataEmpty(), this.data);
        setTimeout(() => {
          this.isNoData().then((data) => {
            res(data);
          });
        }, 100);
      } else {
        res(false);
      }
    });
  }

  setActiveFilter(index: number) {
    this.data = new ContractorDataSource(
      this.contractorApi,
      this.projectApi,
      this.notif,
      this.isVieweByInvites,
    );

    const buttons = this.el.nativeElement.getElementsByClassName('option');
    for (const button of buttons) {
      button.classList.remove('active');
    }

    buttons[index].classList.add('active');
    this.clearSelections();
    this.clearSort(null);
    this.scroll.update();

    this.currentFilter = index;

    switch (index) {
      case 0:
        this.state = CONTRACTOR_SELECTOR.ALL;
        // this.isVieweByInvites.emit(false);
        break;
      case 1:
        this.state = CONTRACTOR_SELECTOR.PREFERRED;
        // this.isVieweByInvites.emit(false);
        break;
      default:
        console.warn('NOT IMPLEMENTED!');
    }

    this.data.changeFilter(this.state);
    this.virtualScroll.scrollToIndex(0);
    this.data.dataStream.pipe(take(2)).subscribe((ev) => {
      this.updateScrollbar();
    });
  }

  clearSelections() {
    this.contractosSelected = [];

    this.data.cachedData.forEach((contractor) => {
      contractor.selected = false;
    });
  }

  sortByColumn(column: string) {
    this.clearSort(column);

    if (this.columnSort[column].sort === SORT_DIR.NONE) {
      this.columnSort[column].sort = SORT_DIR.ASC;
    } else if (this.columnSort[column].sort === SORT_DIR.ASC) {
      this.columnSort[column].sort = SORT_DIR.DESC;
    } else {
      this.columnSort[column].sort = SORT_DIR.NONE;
    }

    this.data.changeSort(column, this.columnSort[column].sort);
  }

  clearSort(column) {
    for (const key of Object.keys(this.columnSort)) {
      if (column !== key) {
        this.columnSort[key].sort = SORT_DIR.NONE;
      }
    }
  }

  isAllSelected() {
    let numSelected = 0;
    const numRows = this.data.cachedData.length;
    this.data.cachedData.forEach((data) => {
      numSelected += data.reduce((acc, cur) => (cur.selected === true ? ++acc : acc), 0);
    });
    return numSelected === numRows && numRows !== 0;
  }

  isOneSelected() {
    let numSelected = 0;
    this.data.cachedData.forEach((data) => {
      numSelected += data.reduce((acc, cur) => (cur.selected === true ? ++acc : acc), 0);
    });
    return numSelected === 0;
  }

  /** DEPRECATED -- Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.data.cachedData.forEach((obj) => {
          obj.items.forEach((contractor) => {
            contractor.selected = false;
          });
        })
      : this.data.cachedData.forEach((obj) => {
          obj.items.forEach((contractor) => {
            if (this.state === 'preferred') {
              if (contractor.is_favorite === 1) {
                contractor.selected = true;
              }
            } else {
              contractor.selected = true;
            }
          });
        });
  }

  toggle(element) {
    element.selected = !element.selected;
    if (element.selected) {
      this.contractosSelected.push(element.id);
    } else {
      const elementIndex = this.contractosSelected.indexOf(element.id, 0);
      this.contractosSelected.splice(elementIndex, 1);
    }
  }

  inviteProjects(contractorId = null) {
    let contrators = [];

    if (contractorId == null) {
      contrators = [...this.contractosSelected];
      // this.contractosSelected = [];
      // for (const objArr of this.data.cachedData) {
      //   for (const obj of objArr.items) {
      //     if (obj.selected) {
      //       contrators.push(obj.id);
      //     }
      //     console.log(contrators, obj);
      //   }
      // }
    } else {
      contrators.push(contractorId);
    }

    const title =
      contrators.length > 1
        ? contrators.length + ' Contractors'
        : this.data.cachedData.filter((o) => o.id === contrators[0])[0].user.company_name;

    this.barState.openInviteProject(contrators, title);
  }

  toggleFavorite(contracotr) {
    this.openLoading();
    this.contractorApi.setFavorite(contracotr.id, !contracotr.is_favorite).then(
      (data) => {
        contracotr.is_favorite = !contracotr.is_favorite;
        this.closeLoading();
        if (contracotr.is_favorite === false && this.state === CONTRACTOR_SELECTOR.PREFERRED) {
          this.setActiveFilter(this.currentFilter);
        }
      },
      (err) => {
        this.notif.showError(err);
      },
    );
  }

  registerSearch(searchText: string) {
    if (!searchText) {
      this.data.searchPage('');
      return;
    }

    this.data.searchPage(searchText);
  }

  registerOption(event: any, contractor, project) {
    switch (event) {
      case OPTIONS.MESSAGE:
        console.log(contractor);
        this.interactionBarState.openMessagePerson(contractor.user);
        break;

      case OPTIONS.CANCEL:
        if (contractor.bid) {
          this.notif.showError('Contractor already submitted a bid!');
          return;
        }

        this.notif.showPopup('Cancel invite?').then((response) => {
          if (response === true) {
            this.openLoading();
            console.log(contractor, event);
            const invite = contractor.invites.find((o) => o.contractor_id === contractor.id);
            if (invite) {
              this.projectApi.deleteInvite(invite.id).subscribe({
                next: (data) => {
                  this.closeLoading();
                  this.ngOnInit();
                  this.setActiveFilter(2);
                },
                error: (err) => {
                  this.notif.showError(err);
                },
              });
            } else {
              this.notif.showError('Invite not found!');
            }
          }
        });
        break;
    }
  }

  ngOnDestroy(): void {
    this.closeLoading();
  }

  getKeywords(keywords: any) {
    let val = '';
    keywords.forEach((keyword, index) => {
      if (index === 0) {
        val += keyword;
      } else {
        val += ', ' + keyword;
      }
    });
    return val;
  }
}
