import { Component, inject, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IBudgetTagTemplate,
  IBudgetTemplate,
  TemplateViews,
} from '../../../../../store/templates/templates.types';
import { templateSelectors } from '../../../../../store/templates/templates.selectors';
import { Store } from '@ngrx/store';
import { templatesActions } from '../../../../../store/templates/templates.actions';
import { AsyncPipe, NgClass } from '@angular/common';
import { NgScrollbar } from 'ngx-scrollbar';
import { FormsModule } from '@angular/forms';
import { TemplateInputComponent } from './template-input/template-input.component';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
  standalone: true,
  imports: [FormsModule, NgScrollbar, NgClass, AsyncPipe, TemplateInputComponent],
})
export class TemplateListComponent {
  store = inject(Store);

  @ViewChild('editInput') editInputElement: HTMLInputElement;
  templateType: TemplateViews;
  @Input({ required: true }) set type(templateType: TemplateViews) {
    this.templateType = templateType;

    if (templateType === 'budget') {
      this.selectedTemplate = this.store.select(templateSelectors.getSelectedBudgetTemplate);
      this.templates$ = this.store.select(templateSelectors.getBudgetTemplates);
    }
    if (templateType === 'budget-tag') {
      this.selectedTemplate = this.store.select(templateSelectors.getSelectedBudgetTagTemplate);
      this.templates$ = this.store.select(templateSelectors.getBudgetTagTemplates);
    }
  }
  templateName = '';
  selectedTemplate: Observable<IBudgetTemplate | IBudgetTagTemplate>;
  templates$: Observable<IBudgetTemplate[] | IBudgetTagTemplate[]>;

  selectTemplate(id: number | string) {
    if (this.templateType === 'budget') {
      this.store.dispatch(templatesActions.setSelectedBudgetTemplate({ templateId: id }));
    }
    if (this.templateType === 'budget-tag') {
      this.store.dispatch(templatesActions.setSelectedBudgetTagTemplate({ templateId: id }));
    }
  }
}
