<div class="contents">
  <div class="index-box">{{ templateItem.order ?? 1 }}</div>
  <span
    [ngClass]="{ 'disabled text-shade_4': !template?.permissions?.can_edit || template.tags.length < 2 }"
    cdkDragHandle
    class="icon-draggable text-color_secondary text-[20px] cursor-pointer"
  ></span>
  <app-floating-input
    [name]="'name' + templateItem.id"
    (modelChange)="fieldChanged('name', $event)"
    [model]="templateItem.name"
    [verticalSpacing]="false"
    [disabled]="!template?.permissions?.can_edit"
  ></app-floating-input>

  <span
    (click)="minusClicked()"
    [ngClass]="{ 'disabled hidden': !template?.permissions?.can_edit, 'disabled grayscale': template.tags.length < 2 }"
    class="icon-minus-box text-color_red_light add-remove-btn"
  ></span>
  <span
    (click)="plusClicked()"
    [ngClass]="{ 'disabled hidden': !template?.permissions?.can_edit }"
    class="icon-plus-box text-color_secondary add-remove-btn"
  ></span>
</div>
