import { Component, inject, OnInit } from '@angular/core';
import { templatesActions } from '../../../store/templates/templates.actions';
import { Store } from '@ngrx/store';
import { templateSelectors } from '../../../store/templates/templates.selectors';
import { NotificationsService } from '../../../services/notifications.service';
import { BudgetTagTemplatesComponent } from '../settings/templates/budget-tag-templates/budget-tag-templates.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { GeneralHeaderComponent } from '../../../framework/general-header/general-header.component';
import { TabSelectorButtonComponent } from '../../../framework/buttons/tab-selector-button/tab-selector-button.component';
import { ProjectBudgetTemplatesComponent } from '../settings/templates/project-budget-templates/project-budget-templates.component';
import {
  TEMPLATE_VIEWS,
  TEMPLATES_AUTO_SAVE_STATES,
  TemplateViews,
} from '../../../store/templates/templates.types';
import { CurrentUserService } from '../../../services/current-user.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BudgetTagTemplatesComponent,
    GeneralHeaderComponent,
    TabSelectorButtonComponent,
    ProjectBudgetTemplatesComponent,
    AsyncPipe,
  ],
})
export class TemplatesComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly notif = inject(NotificationsService);
  protected readonly userService = inject(CurrentUserService);

  TEMPLATE_VIEWS = TEMPLATE_VIEWS;
  templateView: TemplateViews = TEMPLATE_VIEWS.BUDGET;
  isLoading = this.store.select(templateSelectors.getIsLoading);
  autoSaveStatus$ = this.store.select(templateSelectors.getAutoSaveStatus(this.templateView));
  protected readonly TEMPLATES_AUTO_SAVE_STATES = TEMPLATES_AUTO_SAVE_STATES;

  ngOnInit(): void {
    this.store.dispatch(templatesActions.loadBudgetTemplate());
    if (this.userService.isManager) {
      this.store.dispatch(templatesActions.loadBudgetTagTemplates());
    }

    this.isLoading.subscribe((isLoading) => {
      if (isLoading) {
        this.notif.showLoading();
      } else {
        this.notif.close();
      }
    });
  }

  selectTemplateView(view: TEMPLATE_VIEWS) {
    this.templateView = view;
    this.autoSaveStatus$ = this.store.select(
      templateSelectors.getAutoSaveStatus(this.templateView),
    );
  }

  /**
   * @deprecated in favor of auto-save, but we might want to keep this around for manual saves
   */
  saveTemplates() {
    if (this.templateView === 'budget') {
      this.store.dispatch(templatesActions.saveBudgetTemplates({}));
    } else if (this.templateView === 'budget-tag') {
      this.store.dispatch(templatesActions.saveBudgetTagTemplate({}));
    }
  }
}
