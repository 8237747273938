import { createAction, props } from '@ngrx/store';
import {
  IPrimeContractModel,
  IPrimeContractModelResponse,
  IPrimeLineItemResponse,
} from './prime-commitments.types';

const onSidebarOpen = createAction('[prime sidebar] sidebar open');
const onFailedToLoadLineItems = createAction('[prime sidebar] failed to load line items');
const onFailedToSubmitPrimeContract = createAction('[prime sidebar] failed to save prime contract');
const lineItemsLoaded = createAction(
  '[prime sidebar] line items loaded',
  props<{ lineItems: IPrimeLineItemResponse[] }>(),
);

const onPrimeContractUpdate = createAction(
  '[prime sidebar] prime contract edit',
  props<{ primeContract: IPrimeContractModel }>(),
);

const onPrimeContractSubmit = createAction(
  '[prime sidebar] prime contract submit',
  props<{ primeContract: IPrimeContractModel }>(),
);

const primeContractSaved = createAction(
  '[prime sidebar] prime contract saved',
  props<{ primeContractResponse: IPrimeContractModelResponse }>(),
);

export const primeSidebarFailActions = {
  onFailedToSubmitPrimeContract,
  onFailedToLoadLineItems,
};

export const primeSidebarActions = {
  onSidebarOpen,
  lineItemsLoaded,
  onPrimeContractSubmit,
  onPrimeContractUpdate,
  primeContractSaved,
};
