import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InteractionBarStateService } from '../../services/interaction-bar-state.service';
import { NotificationsService } from '../../services/notifications.service';
import { PresignedFileUploadService } from '../../services/presigned-file-upload.service';
import { PrimeCommitmentsApiService } from '../../services/prime-commitments-api.service';
import { catchError, delay, map, repeat, switchMap, tap } from 'rxjs/operators';
import { primeSidebarActions, primeSidebarFailActions } from './prime-commitments.actions';
import { concatLatestFrom } from '@ngrx/operators';
import { viewProjectSelectors } from '../view-project/view-project.selectors';
import { of } from 'rxjs';

@Injectable()
export class PrimeCommitmentsEffects {
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);
  private readonly notif = inject(NotificationsService);
  private readonly presignedFileUploadService = inject(PresignedFileUploadService);
  private readonly primeApiService = inject(PrimeCommitmentsApiService);
  private readonly interactionBar = inject(InteractionBarStateService);

  onPrimeSidebarOpen = createEffect(() => {
    return this.actions.pipe(
      ofType(primeSidebarActions.onSidebarOpen),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) => {
        return this.primeApiService.getLineItems(projectId);
      }),
      map((response) => primeSidebarActions.lineItemsLoaded({ lineItems: response })),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while loading commitments',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't load commitments!");
        }
        return of(primeSidebarFailActions.onFailedToLoadLineItems());
      }),
      repeat(),
    );
  });

  onPrimeContractSaved = createEffect(
    () => {
      return this.actions.pipe(
        ofType(primeSidebarActions.primeContractSaved),
        tap(() => {
          this.notif.showSuccess('Prime contract saved successfully');
        }),
        delay(500),
        tap(() => {
          this.interactionBar.close();
        }),
        delay(2000),
        tap((data: any) => {
          console.warn(data);
          this.interactionBar.editPrimeContract(data.id);
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  onPrimeContractSubmit = createEffect(() => {
    return this.actions.pipe(
      ofType(primeSidebarActions.onPrimeContractSubmit, primeSidebarActions.onPrimeContractUpdate),
      concatLatestFrom(() => this.store.select(viewProjectSelectors.selectProjectId)),
      switchMap(([action, projectId]) => {
        if (action.type === primeSidebarActions.onPrimeContractUpdate.type) {
          return this.primeApiService.updatePrimeContract(projectId, action.primeContract);
        }
        return this.primeApiService.createPrimeContract(projectId, action.primeContract);
      }),
      map((response) => primeSidebarActions.primeContractSaved(response)),
      catchError((error) => {
        if (error) {
          console.error(error);
          this.notif.showError(
            error?.error?.message || 'An error occurred while submitting prime contract',
          );
        } else {
          this.notif.showError("Something went wrong. Couldn't submit prime contract!");
        }
        return of(primeSidebarFailActions.onFailedToSubmitPrimeContract());
      }),
      repeat(),
    );
  });
}
