import { AfterViewInit, Component, inject, Input, OnInit } from '@angular/core';
import { SidebarHeaderComponent } from '../sidebar-header/sidebar-header.component';
import { FormsModule } from '@angular/forms';
import { primeSidebarActions } from '../../../store/prime-commitments/prime-commitments.actions';
import { Store } from '@ngrx/store';
import {
  IPrimeContractModel,
  IPrimeLineItem,
  IPrimeLineItemResponse,
} from '../../../store/prime-commitments/prime-commitments.types';
import {
  primeContractResponseToModel,
  primeContractStatuses,
} from '../../../store/prime-commitments/prime-commitments.constants';
import { DeepCopyService } from '../../../services/deep-copy.service';
import { PrimeContractGeneralPageComponent } from './prime-contract-general-page/prime-contract-general-page.component';
import { PrimeContractValuesComponent } from './prime-contract-values/prime-contract-values.component';
import { PrimeChangeOrderMarkupsComponent } from './prime-change-order-markups/prime-change-order-markups.component';
import { PrimeInvoiceRetainageComponent } from './prime-invoice-retainage/prime-invoice-retainage.component';
import { filter, take } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { primeCommitmentsSelectors } from '../../../store/prime-commitments/prime-commtiments.selectors';
import { PrimeCommitmentsApiService } from '../../../services/prime-commitments-api.service';
import { NotificationsService } from '../../../services/notifications.service';
import { InteractionBarStateService } from '../../../services/interaction-bar-state.service';
import { TooltipModule } from 'primeng/tooltip';
import { PrimeContractUploadTemplateComponent } from './prime-contract-upload-template/prime-contract-upload-template.component';

@Component({
  selector: 'app-prime-contract-sidebar',
  standalone: true,
  imports: [
    SidebarHeaderComponent,
    FormsModule,
    PrimeContractGeneralPageComponent,
    PrimeContractValuesComponent,
    PrimeChangeOrderMarkupsComponent,
    PrimeInvoiceRetainageComponent,
    TooltipModule,
    PrimeContractUploadTemplateComponent,
  ],
  templateUrl: './prime-contract-sidebar.component.html',
})
export class PrimeContractSidebarComponent implements OnInit, AfterViewInit {
  private readonly store = inject(Store);
  private readonly primeApiService = inject(PrimeCommitmentsApiService);
  private readonly notif = inject(NotificationsService);
  private readonly interactionBar = inject(InteractionBarStateService);

  @Input() loadContractById: number;

  currentPage: 1 | 2 | 3 | 4 = 1;
  statuses = primeContractStatuses;
  model: IPrimeContractModel = {
    files: [],
    name: '',
    owner_name: '',
    date: '',
    status: '',
    note: '',
    defaultInvoiceRetainageRate: '0',
    lineItems: [],
    lineItemMarkup: [],
    retainageSettings: [],
  };

  allLineItems$: Observable<IPrimeLineItemResponse[]> = this.store.select(
    primeCommitmentsSelectors.selectAllLineItems,
  );
  showPrimeTemplateUploadPage = false;

  ngOnInit() {
    this.store.dispatch(primeSidebarActions.onSidebarOpen());
    this.allLineItems$
      .pipe(
        filter((items) => !!items.length),
        take(1),
      )
      .subscribe((lineItems) => {
        const nullValueLineItems = lineItems.map((item, index): IPrimeLineItem => {
          return {
            ...item,
            value: null,
            index,
          };
        });
        this.model = {
          ...this.model,
          lineItems: DeepCopyService.deepCopy(nullValueLineItems),
        };
      });
  }

  ngAfterViewInit() {
    if (this.loadContractById) {
      const contract$ = this.primeApiService.loadPrimeContract(this.loadContractById).pipe(take(1));
      const allLineItems$ = this.allLineItems$.pipe(
        filter((items) => !!items.length),
        take(1),
      );
      forkJoin({
        contract: contract$,
        allLineItems: allLineItems$,
      }).subscribe({
        next: ({ contract, allLineItems }) => {
          this.model = DeepCopyService.deepCopy(
            primeContractResponseToModel(contract, allLineItems),
          );
        },
        error: (error) => {
          this.notif.showError('Failed to load prime contract.');
          this.interactionBar.close();
        },
      });
    }
  }

  saveContract() {
    if (this.model.id) {
      this.store.dispatch(
        primeSidebarActions.onPrimeContractUpdate({
          primeContract: DeepCopyService.deepCopy(this.model),
        }),
      );
      return;
    }

    this.store.dispatch(
      primeSidebarActions.onPrimeContractSubmit({
        primeContract: DeepCopyService.deepCopy(this.model),
      }),
    );
  }

  goToNextPage() {
    console.warn(this.model);
    this.currentPage++;
  }

  goToPreviousPage() {
    if (this.showPrimeTemplateUploadPage) {
      this.showPrimeTemplateUploadPage = false;
      return;
    }

    if (this.currentPage === 1) {
      return;
    }
    this.currentPage--;
  }

  addValuesFromLineItems() {
    this.allLineItems$.pipe(take(1)).subscribe((lineItems) => {
      const lineItemMap = new Map(lineItems.map((item) => [item.id, item]));
      this.model.lineItems.forEach((item) => {
        item.value = lineItemMap.get(item.id)?.current_budget;
      });
    });
  }

  changeViewToUploadPage() {
    this.showPrimeTemplateUploadPage = true;
  }

  replaceDataWithTemplate(templateValues: IPrimeLineItem[]) {
    if (!templateValues.length) {
      this.notif.showError('Could not identify any template.');
      return;
    }
    this.model.lineItems = templateValues;
    this.showPrimeTemplateUploadPage = false;
  }
}
