import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { oldOptionOverlayPositions } from '../option-list.constants';
import { OptionsListGeneralComponent } from '../options-list-general/options-list-general.component';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { DefaultOptionsListComponent } from '../../default-options-list/default-options-list.component';
import { BehaviorSubject } from 'rxjs';
import { OPTIONS } from '../../constants/options-list.constants';

@Component({
  selector: 'app-un-styled-options-list',
  standalone: true,
  imports: [
    CommonModule,
    OptionsListGeneralComponent,
    CdkOverlayOrigin,
    DefaultOptionsListComponent,
  ],
  templateUrl: './un-styled-options-list.component.html',
  styleUrls: ['./un-styled-options-list.component.scss'],
})
export class UnStyledOptionsListComponent implements OnDestroy {
  // TODO: remove this contentChild thing and use ng-content properly with default value
  @ContentChild('projectedContent') projectedContentTemplate: TemplateRef<any> = null;
  @ViewChild('optionsListGeneralComponent')
  optionsListGeneralComponent: OptionsListGeneralComponent;
  @Input() listItems: Array<OPTIONS> = [];
  @Input() disabledActionList: Array<OPTIONS> = [];
  @Output() selectedOption = new EventEmitter();
  @Output() showOverlay$ = new BehaviorSubject<boolean>(false);
  protected readonly optionOverlayPositions = oldOptionOverlayPositions;

  registerSelectedOption(value: string) {
    this.selectedOption.emit(value);
    this.optionsListGeneralComponent.toggleShow();
  }

  ngOnDestroy() {
    this.showOverlay$.complete();
  }
}
