import { Component, inject, Input } from '@angular/core';
import { FloatingInputComponent } from '../../../../../framework/inputs/floating-input/floating-input.component';
import {
  IBudgetTemplate,
  IBudgetTemplateItemGC,
  IBudgetTemplateSubitemGC,
} from '../../../../../store/templates/templates.types';
import { isManagerTemplateItem } from '../../../../../store/templates/templates.constants';
import { templatesActions } from '../../../../../store/templates/templates.actions';
import { Store } from '@ngrx/store';
import { CurrentUserService } from '../../../../../services/current-user.service';
import { CdkDragHandle } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-template-subitem',
  standalone: true,
  imports: [FloatingInputComponent, CdkDragHandle, NgClass],
  templateUrl: './template-subitem.component.html',
  styleUrl: './template-subitem.component.scss',
})
export class TemplateSubitemComponent {
  @Input({ required: true }) template: IBudgetTemplate;
  @Input({ required: true }) templateItem: IBudgetTemplateItemGC;
  @Input({ required: true }) templateSubitem: IBudgetTemplateSubitemGC;

  isManagerTemplate = isManagerTemplateItem;

  userService = inject(CurrentUserService);
  store = inject(Store);

  minusClicked() {
    this.store.dispatch(
      templatesActions.removeBudgetTemplateSubitem({
        itemId: this.templateSubitem.parent_id,
        subitemId: this.templateSubitem.id,
      }),
    );
  }

  // plusClicked() {
  //   this.store.dispatch(
  //     templatesActions.addEmptySubitemToBudgetTemplateItem({ itemId: this.templateItem.id }),
  //   );
  // }

  fieldChanged(field: 'account_code' | 'division' | 'cost_type' | 'description', value: string) {
    this.store.dispatch(
      templatesActions.updateBudgetTemplateSubitem({
        templateItemId: this.templateSubitem.parent_id,
        templateSubitemId: this.templateSubitem.id,
        templateSubitem: { [field]: value },
      }),
    );
  }
}
